import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
interface Option {
  label: string;
  icon: ReactNode;
  token: string;
}
interface CardMoreOptionsProps {
  options: any[];
  onSelectOption: (option: object) => void;
}

const CardMoreOptions: React.FC<CardMoreOptionsProps> = ({
  options,
  onSelectOption,
}) => {
  const handleOptionSelect = (e: any, option: Option) => {
    e.stopPropagation();
    onSelectOption(option);
  };
  const { t } = useTranslation();

  return (
    <div className="relative">
      <div className="w-auto px-[1.9rem] py-[0.5rem] bg-io-white dark:bg-io-black-1a shadow-lg rounded-[0.6rem] ">
        <ul className="w-full h-auto">
          {options.map((option, index) => (
            <li
              key={index}
              className="w-full h-auto flex justify-start items-center gap-4 group cursor-pointer"
              onClick={(e: any) => handleOptionSelect(e, option)}
            >
              <div className="w-[2rem] h-auto">{option.icon}</div>
              <span className="text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-gray-e9 text-nowrap py-3">
                {t(option.token)}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="absolute top-[-2rem] right-4 w-auto h-auto border-b-[1rem] border-b-io-white dark:border-b-io-black-1a border-t-[1rem] border-t-transparent border-s-[1rem] border-s-transparent border-e-[1rem] border-e-transparent"></div>
    </div>
  );
};
export default CardMoreOptions;
