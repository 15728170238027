import { useState, useEffect } from "react";
import Authentication from "../../../auth/Authentication";
import ThemeToggleButton from "../../form/theme-toggle-btn";
import { NotificationSVG, SettingsSVG, HeaderProfileDefault } from "../../svg";
import defaultImage from "../../../assets/images/card-grdi-default.png";
interface HeaderProps {
  activeModal: string;
  handleClick: (data: string) => void;
  themeToggled?: () => void;
}
function Header({ activeModal, handleClick, themeToggled }: HeaderProps) {
  const authService = Authentication();
  let parsedLoginDetails = null;
  parsedLoginDetails = authService.decryptData("UserDetails", 2);
  parsedLoginDetails = parsedLoginDetails || "{}";
  const otherDetails = parsedLoginDetails;
  const [profileImage, setProfileImage] = useState(
    otherDetails?.imageUrl || defaultImage
  );
  const handleImageError = (event: any) => {
    event.target.src = defaultImage;
  };
  const [remainingDays, setRemainingDays] = useState<number | null>(null);
  const [titleData, setTitle] = useState("");
  // Ensure the environment variable is properly read as a number
  const expiryDays = parseInt(process.env.REACT_APP_EXPIRY_DAYS || "5");

  useEffect(() => {
    const days = sessionStorage.getItem("passwordRemainingDays");

    if (days !== null) {
      const remaining = parseInt(days); // Ensure proper integer parsing

      if (!isNaN(remaining) && remaining !== remainingDays) {
        setRemainingDays(remaining);

        // Update title based on the new remaining days
        if (remaining <= expiryDays) {
          setTitle(`Your password is going to expire in ${remaining} days.`);
        } else {
          setTitle("");
        }
      }
    }
  }, [remainingDays, expiryDays]); // Add expiryDays to dependencies

  return (
    <div className="w-full h-[6rem] bg-io-primary">
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-full h-auto mx-8">
          <div className="w-full h-auto flex justify-between items-center">
            {/* <h1 className="text-[1.8rem] font-inter font-normal text-io-white uppercase">
              Inspection one
            </h1> */}
            <div className="w-[20.4rem] h-[6.4rem] overflow-hidden rounded-full flex justify-center items-center">
              <img
                src={
                  "https://inspectionone-host.azurewebsites.net/assets/images/inspection-one.png"
                }
                alt="InspectionOne"
              />
            </div>

            <ul className="w-auto h-auto flex justify-end items-center gap-[3rem]">
              <li
                className={`w-[2rem] h-auto relative ${
                  activeModal === "notification" && "active-arrow"
                }`}
              >
                <button
                  type="button"
                  className={`w-full h-auto relative ${
                    titleData !== "" &&
                    "after:content-[''] after:w-[1rem] after:h-[1rem] after:bg-red-500 after:absolute after:top-0 after:right-0 after:rounded-full after:border after:border-io-primary"
                  } `}
                  onClick={() => handleClick("notification")}
                >
                  <NotificationSVG />
                </button>
              </li>
              <li
                className={`w-[2rem] h-auto relative ${
                  activeModal === "settings" && "active-arrow"
                }`}
              >
                <button
                  type="button"
                  className="w-full h-auto"
                  onClick={() => handleClick("settings")}
                >
                  <SettingsSVG />
                </button>
              </li>
              <li className="w-auto h-auto pb-2">
                <ThemeToggleButton themeToggled={themeToggled} />
              </li>
              <li
                className={`w-[4rem] h-[4rem] rounded-full overflow-hidden relative ${
                  (activeModal === "profile" ||
                    activeModal === "viewaccount") &&
                  "active-profilearrow"
                }`}
                onClick={() => handleClick("profile")}
              >
                {profileImage ? (
                  <img
                    className="object-cover object-center"
                    src={profileImage}
                    alt="ProfileImage"
                    onError={(e) => handleImageError(e)}
                  />
                ) : (
                  <HeaderProfileDefault />
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
