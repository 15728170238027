import { useNavigate, useParams } from "react-router-dom";
import { InputField } from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import { masterAccess } from "../../../../services/InspectionOneServices";
import MasterDetails from "../../../../pages/master-details/master-details";

function CreateUserMasterForm({ headerDetails, userMasterUpdated }: any) {
  const { id } = useParams();
  const [initialData, setInitialData] = useState<any>([]);
  const [fieldData, setFieldData] = useState<any>([]);
  const [dynamicShape, setDynamicShape] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (initialData.length > 0) {
      const titles = initialData.map((item: any) => {
        const keys = Object.keys(item);
        const values = Object.values(item);
        const headerDetailsList = headerDetails;
        const labelValuePairs = keys.map((key, i) => ({
          label: key,
          value: values[i],
          isMandatory:
            headerDetailsList[i - 1] !== undefined
              ? headerDetailsList[i - 1].isMandatory
              : 0,
          headerName:
            headerDetailsList[i - 1] !== undefined
              ? headerDetailsList[i - 1].headerName
              : "",
        }));
        return labelValuePairs;
      });
      setFieldData(titles[0]);
      const shape = titles[0].reduce((acc: any, item: any) => {
        if (item.isMandatory) {
          acc[item.label] = yup.string().required(`${item.label} is required`);
        } else {
          acc[item.label] = yup.string();
        }
        return acc;
      }, {});
      setDynamicShape(shape);
    }
  }, [initialData]);

  useEffect(() => {
    if (id !== "new") {
      handleMasterEdit(id);
    } else {
      const initialObject = headerDetails.reduce((acc: any, column: any) => {
        acc[column.field] = "";
        return acc;
      }, {});
      setInitialData([{ id: 0, ...initialObject }]);
    }
  }, [id]);

  const createFormSchema = yup.object().shape(dynamicShape);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(createFormSchema),
  });

  const navigate = useNavigate();

  const handleFormSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      if (data.id === "") {
        data.id = 0;
      }
      createFormSchema.validate(data);
      const requestJson = {
        accessUniqueId: masterId.trim(),
        masterValueId: id,
        actionMode: 1,
        masterDetails: [data],
      };
      const response = await masterAccess.MasterDetailsAction(requestJson);
      if (response.success) {
        setIsLoading(false);
        //setInitialData(response.data.masterValueDetails);
        userMasterUpdated(true);
        navigate(-1);
      } else {
        setIsLoading(false);
        navigate(-1);
      }
    } catch (error) {
      setIsLoading(false);
      navigate(-1);
    }
  };

  const handleFormCancel = () => {
    navigate(-1);
  };

  const currentUrl: string = window.location.href;
  const parts = currentUrl.split("/");
  const masterId = parts[parts.length - 3];

  const handleMasterEdit = async (uniqueId: any) => {
    try {
      setIsLoading(true);
      const requestJson = {
        masterAccessId: masterId.trim(),
        masterValueId: uniqueId.trim(),
      };
      const response = await masterAccess.MasterDetailsActionFetch(requestJson);
      if (response.success) {
        setIsLoading(false);
        setInitialData(response.data.masterValueDetails);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <form
        className="w-full h-full flex flex-col"
        onSubmit={handleSubmit((data) => handleFormSubmit(data))}
      >
        <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
          <div className="w-[93%] h-auto">
            <div className="w-full h-auto flex justify-between items-start pt-4">
              <div className="w-[60%] ">
                <div className="w-full h-auto">
                  <div className="w-full mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <>
                        {fieldData.map((field: any, index: number) => {
                          const isHidden = index === 0;
                          setValue(field.label, field.value);
                          return (
                            <div
                              key={field.label}
                              style={{
                                marginBottom: "20px",
                                display: isHidden ? "none" : "block",
                              }}
                            >
                              <InputField
                                labelName={field.headerName}
                                type="text"
                                id={field.label}
                                isRequired={
                                  field.isMandatory === 1 ? true : false
                                }
                                formHandle={register(field.label)}
                                error={(errors as any)[field.label]?.message}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalPageFooter
          footerType="form"
          handleCancel={() => handleFormCancel()}
          isLoading={isLoading}
        />
      </form>
    </div>
  );
}
export default CreateUserMasterForm;
