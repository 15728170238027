import { useEffect, useState } from "react";
import LoginWithAzure from "../../UI/login-with-azure";
import { InputFieldWithoutForm } from "../../form/input-fields";
import { LoginBtn } from "../../form/text-btn";
import { useNavigate } from "react-router";
import Authentication from "../../../auth/Authentication";
import { useTranslation } from "react-i18next";
import { isValidEmailAddress } from "../../../utils/common";
import { domainSubmit, loginSubmit } from "../../../services/authFunctions";
import { loginValidation } from "../../../services/validation";
import ReCAPTCHA from "react-google-recaptcha";
import { useMyContext } from "../../my-context/MyContext";
import Skeleton from "react-loading-skeleton";
function LoginSteps({ setPageLoader }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authService = Authentication();
  const { loadProviderData } = useMyContext();
  const [currentStep, setCurrentStep] = useState<number>(2);
  const [isLoading, setIsLoading] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  //#region Domain
  const [tenantLogoUrl, setTenantLogoUrl] = useState("");

  const getSubdomain = (): string | null => {
    const { hostname } = window.location;
    const hostParts = hostname.split(".");
    if (
      hostname.includes("localhost") ||
      hostname.includes("inspectionone-host-staging.azurewebsites.net")
    ) {
      const domain = sessionStorage.getItem("domain");

      return domain;
    }
    if (hostParts.length > 2) {
      return hostParts[0];
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setPageLoader(true);
      setIsImgLoading(true);
      const result = await domainSubmit(getSubdomain(), false);
      if (result.success) {
        authService.login(1);
        setTenantLogoUrl(result.response.tenantLogo);
        authService.encryptData(result.data, "serviceDetails", 1);
        authService.encryptData(false, "isGridView", 2);
        setCurrentStep(2);
        setIsLoading(false);
        setPageLoader(false);
        setIsImgLoading(false);
      } else {
        setIsLoading(false);
        setPageLoader(false);
        setIsImgLoading(false);
      }
    };

    fetchData();
  }, []);

  //#endregion
  //#region Login
  const [emailValue, setEmail] = useState("");
  const [passwordValue, setPassword] = useState("");
  const [errorEmailMessage, setEmailError] = useState<string>("");
  const [errorPasswordMessage, setPasswordMessage] = useState<string>("");
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [captchaError, setCaptchaError] = useState<string>("");
  const [captcha, setCaptcha] = useState<boolean>(false);

  const captchaSiteKey = "6LcG5v0pAAAAAHGfx_tKiMINcy0HnV4oQkGXNM3p";

  const handleEmailChange = (email: any) => {
    setEmail(email.trim());
    setEmailError(
      email.trim().length > 5 && !isValidEmailAddress(email)
        ? "web.login.alert.invalidemail"
        : ""
    );
  };
  const handlePasswordChange = (password: any) => {
    setPassword(password);
    setPasswordMessage("");
  };
  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };
  const handleLoginValidation = () => {
    const { emailError, passwordError } = loginValidation(
      emailValue,
      passwordValue
    );

    setEmailError(emailError);
    setPasswordMessage(passwordError);

    return !emailError && !passwordError;
  };
  const handleLoginSubmit = async () => {
    // Check if the captcha is required and not completed
    if (!captchaValue && captcha) {
      setCaptchaError("Please complete the reCAPTCHA");
      return;
    }

    // Proceed with login validation
    if (handleLoginValidation()) {
      setIsLoading(true);
      setPageLoader(true);
      // Attempt to submit login credentials
      const result = await loginSubmit(emailValue, passwordValue);

      if (result.success) {
        // Handle special login conditions
        if (
          result.data?.freshlogin === 1 ||
          result.data?.isPasswordExpired === 1
        ) {
          navigate("/reset-password");
          // setCurrentStep(4);
        } else if (result.data?.loginAttempts) {
          // Show captcha if there have been multiple login attempts
          setCaptcha(true);
          setIsLoading(false);
          setPageLoader(false);
        } else {
          // Handle "remember me" functionality
          if (rememberMe) {
            rememberMeCache();
          }
          authService.encryptData(result.data?.loginDetails, "loginDetails", 2);
          authService.encryptData(
            result.data?.loginDetails.otherDetails,
            "UserDetails",
            2
          );

          sessionStorage.setItem(
            "roleId",
            result.data?.loginDetails.otherDetails.roleId
          );
          authService.encryptData(result.data?.sideMenu, "sideBarDetails", 2);
          authService.encryptData(
            result.data?.userSiteDetails,
            "userSiteDetails",
            2
          );
          authService.login(2);
          loadProviderData();
          // Navigate to the active menu route
          navigate(result.data?.activeMenu.routerLink);
          setIsLoading(false);
          setPageLoader(false);
        }
      } else {
        // Handle cases where the password is expired
        if (result?.isPasswordExpired === 1) {
          navigate("/reset-password");
          setIsLoading(false);
          setPageLoader(false);
          // setCurrentStep(4);
        } else {
          setIsLoading(false);
          setPageLoader(false);
          setIsImgLoading(false);
        }
      }

      // Reset loading states
    } else {
      setIsLoading(false);
      setPageLoader(false);
      setIsImgLoading(false);
    }
  };

  const rememberMeCache = () => {
    authService.encryptData(emailValue.trim(), "rememberedEmail", 1);
    authService.encryptData(passwordValue, "rememberedPassword", 1);
  };
  //#region UseEffect
  //#region

  //#endregion
  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    if (rememberedEmail) {
      const email = authService.decryptData("rememberedEmail", 1) || "";
      const password = authService.decryptData("rememberedPassword", 1) || "";
      setEmail(email);
      setPassword(password);
      setRememberMe(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
    setCaptchaError("");
  };
  const renderStepContent = () => {
    switch (currentStep) {
      case 2:
        return (
          <div className="w-full h-auto mx-auto">
            <div className="w-full h-auto">
              <div className="w-[32rem] h-auto max-3xl:w-[25rem] max-2xl:w-[20rem] mx-auto">
                {isImgLoading ? (
                  <Skeleton width="100%" height={75} borderRadius={3} />
                ) : (
                  <img src={tenantLogoUrl} alt="AI Magix Logo" />
                )}
              </div>
            </div>
            <div className="w-full h-auto mx-auto">
              <div className="w-full h-auto text-center">
                <div className="w-full h-auto mb-[5.4rem]">
                  <h1 className="text-[4.2rem] font-inter font-bold text-io-black-1a mb-[0.7rem]">
                    {t("web.login.label.title")}
                  </h1>
                  {/* <p className="text-[1.8rem] font-inter font-normal text-io-gray-66">
                    {t("web.login.label.subtitle")}
                  </p> */}
                </div>
                <div className="w-[55.5%] h-auto mx-auto">
                  <div className="w-full h-auto">
                    <div className="mb-[5rem]">
                      <InputFieldWithoutForm
                        labelName={t("web.login.text.email")}
                        type="text"
                        id="email"
                        isRequired={true}
                        error={t(errorEmailMessage)}
                        value={emailValue}
                        onChange={handleEmailChange}
                        isLightThemeOnly={true}
                        textColor="text-io-black"
                      />
                    </div>
                    <div className="mb-[3rem]">
                      <InputFieldWithoutForm
                        labelName={t("web.login.text.password")}
                        type="password"
                        id="password"
                        isRequired={true}
                        error={t(errorPasswordMessage)}
                        value={passwordValue}
                        onChange={handlePasswordChange}
                        isLightThemeOnly={true}
                        textColor="text-io-black"
                      />
                    </div>
                    <div className="w-full h-auto mb-[5rem]">
                      <div className="w-full h-auto flex justify-between items-center">
                        <div className="w-auto flex justify-start items-center gap-4">
                          <input
                            type="checkbox"
                            name={t("web.login.text.rememberme")}
                            id="rememberme"
                            onChange={handleCheckboxChange}
                            checked={rememberMe}
                          />
                          <label
                            htmlFor="rememberme"
                            className="text-[1.4rem] font-inter font-normal text-io-gray-66"
                          >
                            {t("web.login.text.rememberme")}
                          </label>
                        </div>
                        {/* <p className="text-[1.4rem] font-inter font-semibold text-io-primary uppercase">
                          <a>{t("web.login.text.forgot")}?</a>
                        </p> */}
                      </div>
                    </div>
                    {captcha && (
                      <div className="captcha-containter">
                        <ReCAPTCHA
                          // sitekey="6LcIGP0pAAAAABM_OpMIJ94b1nmXw3N8XbX_9SCb"
                          sitekey={captchaSiteKey}
                          onChange={handleCaptchaChange}
                        />
                        {captchaError && (
                          <span className="captcha-error">{captchaError}</span>
                        )}
                      </div>
                    )}
                    <div className="w-full mb-[3rem]">
                      <LoginBtn
                        label={t("web.login.button.login")}
                        btnType="button"
                        isLoading={isLoading}
                        handleClick={handleLoginSubmit}
                      />
                    </div>
                    <LoginWithAzure setPageLoader={setPageLoader} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="w-full h-auto">
            <div className="w-full h-auto">
              <div className="w-[32rem] h-auto max-3xl:w-[25rem] max-2xl:w-[20rem] mx-auto">
                <img src={tenantLogoUrl} alt="AI Magix Logo" />
              </div>
            </div>
            <div className="w-full h-auto mx-auto">
              <div className="w-full h-auto text-center">
                <div className="w-full h-auto mb-[5.4rem]">
                  {/* <h1 className="text-[4.2rem] font-inter font-bold text-io-black-1a mb-[0.7rem]">
                     {t("web.login.text.forgot")}
                  </h1> */}
                  {/* <p className="text-[1.8rem] font-inter font-normal text-io-gray-66">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p> */}
                </div>
                <div className="w-[55.5%] h-auto mx-auto">
                  <div className="w-full h-auto">
                    <div className="mb-[5rem]">
                      <InputFieldWithoutForm
                        labelName={t("web.profile.label.email")}
                        type="text"
                        id="forgetEmail"
                        isRequired={true}
                        isLightThemeOnly={true}
                        textColor="text-io-black"
                      />
                    </div>
                    <div className="w-full mb-[3rem]">
                      <LoginBtn
                        label={t("web.section.lable.send")}
                        btnType="button"
                        handleClick={() => navigate("/reset-password")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="w-full flex-1 overflow-y-auto">
      <div className="w-full h-full relative flex justify-center items-center ">
        {renderStepContent()}
      </div>
    </div>
  );
}
export default LoginSteps;
