import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import PillarTypes from "../../../UI/pillar-types";
import ModalListCard from "../../../UI/modal-list-card";
import { ReactSortable } from "react-sortablejs";
import { HandIcon, DeleteIcon, RefreshIcon } from "../../../svg";
import VoiceSearchInput from "../../../form/voice-search-input";
import { generateRandomKey } from "../../../../utils/randomKey";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import IconTextBtn from "../../../form/icon-text-btn";
import {
  magicServices,
  containerServices,
} from "../../../../services/InspectionOneServices";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../my-context/MyContext";
interface Item {
  magicContainerUniqueId: any;
  uniqueId: any;
  id: string;
  order: number;
  magicId: string;
  name: string;
  description: string;
  thumbnail: string;
  icon: string;
  labels: {
    labelId: number;
    name: string;
    color: string;
  }[];
}
const generateGuid = () => {
  return uuidv4();
};
interface ModalListItemsProps {
  items: Item[];
  isLoading: boolean;
  noDataFound: boolean;
}
const Container = ({
  openInputParameter,
  inputParameterParams,
  setContainerItems,
  containerItems,
  setContainerMagicList,
  containerMagicList,
  magixType,
  handleSubmit,
}: any) => {
  const { isExternalUser } = useMyContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const [magicAllList, setMagicAllList] = useState<Item[]>([]);
  const [menuListItems, setMenuListItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const scrollGroundRef = useRef<any>(null);
  const [containerDetIsLoading, setContainerDetIsLoading] =
    useState<boolean>(true);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [magicTypes, setMagicTypes] = useState<Item[]>([]);
  const [startIndex, setStartIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [finalFetch, setFinalFetch] = useState(false);
  const [limit] = useState(10);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  useEffect(() => {
    if (searchedQuery !== undefined) {
      setMagicAllList([]);
      setStartIndex(0);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);

  useEffect(() => {
    initialFetch(currentMagicType, searchedInputVal);
  }, [isFetchMore, searchedInputVal]);

  useEffect(() => {
    if (id) {
      handleContainerEdit(id);
    }
  }, [id]);

  const previousMagicType = useRef<any>(null);

  const [currentMagicType, setCurrentMagicType] = useState("3");

  const initialFetch = async (magicType: any, searchedVal: any) => {
    setIsLoading(true);
    setFinalFetch(false);
    setNoDataFound(false);

    if (magicType !== previousMagicType.current) {
      setMagicAllList([]);
      setStartIndex(0);
      previousMagicType.current = magicType;
    }

    try {
      const requestJson = {
        magicType: magicType ?? 1,
        magicPillar: 1,
        filterDetails: {
          searchValue: searchedVal,
          startIndex: startIndex,
          limit: limit,
          startDate: "",
          endDate: "",
          sorting: "",
          columnName: "",
        },
      };
      const response = await magicServices.MagicAllList(requestJson);
      const responseData = response.data;
      if (response.success) {
        const responseData = response.data;

        //setMagicAllList(responseData.magicDetails);
        setMagicAllList((prevData: any) => [
          ...prevData,
          ...responseData.magicDetails,
        ]);
        setMagicTypes(responseData.magicTypeCounts);
        setTotalCount(responseData.currentMagicTypeCount);
        setIsLoading(false);
        if (responseData?.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
      } else {
        console.error("Error fetching data:");
        setMagicAllList(responseData || []);
      }
    } catch (error) {
      setMagicAllList([]);
      console.error("Error fetching data:", error);
    }
  };

  const handleContainerEdit = async (id: any) => {
    setContainerDetIsLoading(true);
    try {
      const requestJson = {
        uniqueId: id.trim(),
      };
      const response = await containerServices.ContainerDetailFetch(
        requestJson
      );
      if (response.success) {
        setContainerDetIsLoading(false);
        setContainerMagicList(response.data);
        if (response.data.magicDetails != null) {
          const newData = Array.isArray(response.data.magicDetails)
            ? response.data.magicDetails
            : [response.data.magicDetails];
          if (
            newData[0].type === 4 &&
            newData[0].labels &&
            newData[0].labels.length
          ) {
            const newItems = newData[0].labels.map(
              (label: any, index: number) => ({
                isDeleted: 0,
                description: label.description,
                name: label.name,
                icon: label.magicUrl,
                pillar: newData[0].pillar,
                type: newData[0].type,
                order: label.order,
                uniqueId: generateGuid(),
                magicUniqueId: newData[0].uniqueId,
                isNew: 1,
              })
            );
            setMenuListItems([...menuListItems, ...newItems]);
          } else {
            setMenuListItems([...menuListItems, ...newData]);
          }
        }
      } else {
        setContainerDetIsLoading(false);
      }
    } catch (error) {
      setContainerDetIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  const handlePillarChange = (event: any) => {};

  const modalListPillars = [
    { id: "1", label: "Vision" },
    // { id: "2", label: "Document" },
    // { id: "3", label: "Speech" },
    // { id: "4", label: "Text" },
  ];

  const reorder = (
    list: Item[],
    startIndex: number,
    endIndex: number
  ): Item[] => {
    const newList = Array.from(list);
    const [removed] = newList.splice(startIndex, 1);
    newList.splice(endIndex, 0, removed);
    newList.forEach((item, index) => {
      item.order = index + 1;
    });
    return newList;
  };
  const copy = (
    source: any[],
    destination: any[],
    droppableSource: any,
    droppableDestination: any
  ): any[] => {
    const item = source[droppableSource.index];
    const newItem = {
      isDeleted: 0,
      description: item.description,
      name: item.name,
      icon: item.icon,
      pillar: item.pillar,
      type: item.type,
      order: item.order,
      uniqueId: generateGuid(),
      magicUniqueId: item.uniqueId,
      isNew: 1,
    };
    const newDestination = Array.from(destination);
    if (item.type === 4 && item.labels && item.labels.length) {
      const newItemsWithOrder = item.labels.map(
        (label: any, index: number) => ({
          isDeleted: 0,
          description: label.description,
          name: label.name,
          icon: label.magicUrl,
          pillar: item.pillar,
          type: item.type,
          order: label.order, // Assuming label.order is the correct order
          uniqueId: generateGuid(),
          magicUniqueId: item.uniqueId,
          isNew: 1,
        })
      );

      // Insert the new items into the destination array at the correct position
      newItemsWithOrder.forEach((newItemWithLabel: any, index: any) => {
        newDestination.splice(
          droppableDestination.index + index,
          0,
          newItemWithLabel
        );
      });

      // Sort the newDestination array by order
      newDestination.sort((a, b) => a.order - b.order);
    } else {
      newDestination.splice(droppableDestination.index, 0, newItem);
    }

    return newDestination;
  };
  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }
      switch (source.droppableId) {
        case destination.droppableId:
          setMenuListItems((state) =>
            reorder(state, source.index, destination.index)
          );
          break;
        case "MENULISTITEMS":
          setMenuListItems((state) => {
            const updatedState = copy(magicAllList, state, source, destination);
            // Move the newly added item to the end of the list
            const [movedItem] = updatedState.splice(destination.index, 1);
            updatedState.push(movedItem);
            return updatedState;
          });
          break;
        default:
          break;
      }
    },
    [magicAllList]
  );

  const handleInputParameter = () => {
    const itemsWithOrder = containerItems.map((item: any, index: number) => ({
      ...item,
      order: index + 1,
      flowId: generateRandomKey(),
      isActive: true,
      labels: item.labels.map((label: any) => ({
        ...label,
        actionId: label.actionId || "",
        redirectId: label.redirectId || "",
        isCalculation: 0,
        calculationType: "",
        containerLabelUniqueId: generateGuid(),
        magicLabelUniqueId: label.uniqueId,
      })),
    }));

    if (itemsWithOrder.length > 0) {
      openInputParameter(true);
      inputParameterParams({
        containerList: itemsWithOrder,
        editMagicData: containerMagicList,
      });
    }
  };
  const handleSelectionType = (value: string) => {
    initialFetch(value, searchedInputVal);

    setCurrentMagicType(value);
  };

  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = scrollGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };

  const handleDelete = (uniqueId: any) => {
    if (uniqueId === "deleteBlueprint") {
      if (
        typeof containerMagicList === "object" &&
        containerMagicList !== null
      ) {
        // Update the isDeleted property if uniqueId matches
        const updatedMagicList = { ...containerMagicList };

        if (updatedMagicList) {
          updatedMagicList.isDeleted = 1;
        }

        // Update the state with the modified object
        setContainerMagicList(updatedMagicList);
        handleSubmit();
      }
    } else {
      const updatedItems = containerItems.map((item: any) =>
        item.uniqueId === uniqueId ? { ...item, isDeleted: 1 } : item
      );
      setContainerItems(updatedItems);
      const menuListItemsData = menuListItems.map((item: any) =>
        item.uniqueId === uniqueId ? { ...item, isDeleted: 1 } : item
      );
      setMenuListItems(menuListItemsData);
    }
  };

  const handleModalListDelete = () => {};

  const handleModalListRefresh = () => {};

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="w-full flex-1">
        <div className="w-full h-full flex justify-between items-start gap-[4.2rem]">
          <div className="w-[35rem] h-full max-md:w-[28rem]">
            <div className="w-full h-full bg-[#F4F9FD] dark:bg-io-black-23 rounded-[15px] flex flex-col">
              <div className="w-full h-auto px-[3rem] py-[1.5rem] border-b border-b-[#E0E0E0] dark:border-io-black-48 max-md:px-[1.5rem]">
                <div className="w-full h-auto">
                  <select
                    name="selectModalListPillars"
                    id="selectModalListPillars"
                    className="text-[2.8rem] font-inter font-medium text-io-primary bg-transparent"
                    onChange={handlePillarChange}
                  >
                    {modalListPillars.map((pillars, i) => (
                      <option key={i} value={pillars.id}>
                        {pillars.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full h-auto my-9">
                  <PillarTypes
                    isIconOnly={true}
                    data={magicTypes}
                    handleSelectionType={handleSelectionType}
                    magixType={magixType}
                    actionType={
                      containerItems.length > 0 ? "magicFlow" : "magic"
                    }
                  />
                </div>
              </div>
              <div className="w-full h-auto px-[2.6rem] py-[2.1rem] ">
                <div className="w-full h-auto">
                  <VoiceSearchInput
                    inputSize="small"
                    onSearch={(query: any) => setSearchedQuery(query)}
                  />
                </div>
              </div>
              <div
                className="w-full flex-auto basis-0 overflow-y-auto px-[2.6rem] mb-[2.1rem] scrollbar-medium"
                ref={scrollGroundRef}
                onScroll={handleScroll}
              >
                <div className="w-full h-auto">
                  <ModalListItems
                    items={magicAllList}
                    isLoading={isLoading}
                    noDataFound={noDataFound}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 h-full">
            <div className="w-full pt-[2rem] h-full flex flex-col">
              <div className="w-full h-auto flex justify-between items-center max-2xl:flex-col max-2xl:justify-start max-2xl:items-start max-2xl:gap-y-[2rem]">
                <div className="w-auto h-auto">
                  <div className="w-auto">
                    <h1 className="text-[2.8rem] font-inter font-semibold leading-none text-io-black dark:text-io-white">
                       {t("web.magix.label.modellsit")}
                    </h1>
                  </div>
                </div>
                <div className="w-auto h-auto">
                  <div className="w-auto h-auto flex justify-end items-center gap-[2.6rem] max-3xl:gap-[1.8rem] max-2xl:gap-[1.5rem] max-2xl:flex-row-reverse">
                    {/* <div className="w-auto">
                      <GridSearch />
                    </div> */}
                    {/* <div className="w-auto">
                      <IconTextBtn
                        label={t("web.magix.label.inputparameters")}
                        btnSize="large"
                        icon={<InputParametersIcon />}
                        handleClick={handleInputParameter}
                      />
                    </div> */}
                    {currentMagicType === "4" && (
                      <div className="w-auto">
                        <IconTextBtn
                          label={t("web.label.common.delete")}
                          btnSize="large"
                          icon={<DeleteIcon />}
                          handleClick={handleModalListDelete}
                        />
                      </div>
                    )}

                    <div className="w-auto">
                      <IconTextBtn
                        label={t("web.toolbar.label.refresh")}
                        btnSize="large"
                        icon={<RefreshIcon />}
                        handleClick={handleModalListRefresh}
                      />
                    </div>
                    {magixType && isExternalUser && (
                      <div className="w-auto">
                        <IconTextBtn
                          label="Delete"
                          btnSize="small"
                          icon={<DeleteIcon />}
                          handleClick={() => handleDelete("deleteBlueprint")}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full relative flex-1 mt-[2.5rem]">
                {!containerDetIsLoading && menuListItems.length === 0 && (
                  <div className="w-full h-full absolute top-0 left-0 z-[1] border border-dashed border-io-gray-c8 dark:border-io-black-48 rounded-[0.7rem]">
                    <div className="w-full h-full flex justify-center items-center">
                      <div className="w-auto h-auto">
                        <div className="w-[16.5rem] h-auto mb-10 mx-auto">
                          <HandIcon />
                        </div>
                        <p className="text-[2rem] font-inter font-normal text-[#B8B8B8] w-[60%] text-center mx-auto">
                           {t("web.magix.label.draganddropmodels")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {containerDetIsLoading && (
                  <div className="w-full h-full absolute top-0 left-0 z-[2] bg-io-white">
                    <div className="w-full h-auto grid grid-cols-5 max-3xl:grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-2 gap-4">
                      {Array.from({ length: 15 }).map((row: any, i: number) => (
                        <Fragment key={i}>
                          <div className="w-full h-full">
                            <Skeleton
                              width="100%"
                              height={160}
                              borderRadius={4}
                            />
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  </div>
                )}
                <ModalListsContainer
                  items={menuListItems}
                  containerItems={containerItems}
                  setContainerItems={setContainerItems}
                  magixType={magixType}
                  handleDelete={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

const ModalListItems: React.FC<ModalListItemsProps> = ({
  items,
  isLoading,
  noDataFound,
}) => {
  return (
    <Droppable droppableId="MENULISTITEMS" isDropDisabled={true}>
      {(provided, snapshot) => (
        <ul
          ref={provided.innerRef}
          className="w-full h-auto grid grid-cols-2 gap-4 max-md:grid-cols-1"
        >
          {isLoading ? (
            Array.from({ length: 10 }).map((row: any, i: number) => (
              <Fragment key={i}>
                <div className="w-full h-full">
                  <Skeleton width="100%" height={100} borderRadius={4} />
                </div>
              </Fragment>
            ))
          ) : noDataFound ? (
            <p className="text-[1.6rem] font-inter font-normal text-io-gray-33 text-center italic col-span-2">
              No Data Found
            </p>
          ) : (
            items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`w-full h-auto ${
                      snapshot.isDragging ? "dragging" : ""
                    }`}
                  >
                    <ModalListCard type="list" item={item} index={index} />
                  </li>
                )}
              </Draggable>
            ))
          )}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

const sortList = (list: any) => {
  return list.slice().sort((first: any, second: any) => first.id - second.id);
};

const CustomComponent = forwardRef((props: any, ref: any) => {
  return (
    <div
      className="grid grid-cols-5 max-3xl:grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-2"
      ref={ref}
    >
      {props.children}
    </div>
  );
});

const ModalListsContainer: React.FC<any> = ({
  items,
  containerItems,
  setContainerItems,
  magixType,
  handleDelete,
}) => {
  useEffect(() => {
    if (items.length > 0) {
      if (magixType) {
      } else {
        setContainerItems(sortList(items));
      }
    }
  }, [items]);

  return (
    <Droppable droppableId="MENULISTCONTAINER" direction="horizontal">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className="w-full overflow-x-auto h-full z-[2]"
          style={{
            pointerEvents: magixType ? "none" : "auto",
            opacity: magixType ? 0.5 : 1,
          }}
        >
          <ReactSortable
            tag={CustomComponent}
            list={containerItems}
            setList={setContainerItems}
          >
            {containerItems?.length > 0 &&
              containerItems
                .filter((item: any) => item.isDeleted === 0)
                .map((item: any, i: number) => (
                  <div className="w-full" key={i}>
                    <ModalListCard
                      type="container"
                      item={item}
                      index={i}
                      sortedList={containerItems}
                      handleDelete={() => handleDelete(item.uniqueId)}
                    />
                  </div>
                ))}
          </ReactSortable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Container;
