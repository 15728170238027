import { useEffect, useRef, useState } from "react";
import { DeleteIcon, EditIcon, VerticalMoreBlueIcon } from "../../svg";
import IconBtn from "../../form/icon-btn";
import CardMoreOptions from "../../form/card-more-options";
import defaultUserImage from "../../../assets/images/card-grdi-default.png";
import defaultIconImage from "../../../assets/images/card-default-small.png";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../my-context/MyContext";

function CardsViewCard({
  item,
  handleNavigate,
  handleEdit,
  handleDelete,
  isLoading,
  actionType,
}: any) {
  const { t } = useTranslation();
  const { isExternalUser } = useMyContext();
  const [isContentVisible, setContentVisible] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const options: any[] = [
    { label: "Edit", icon: <EditIcon />, token: "web.title.common.edit" },
    { label: "Delete", icon: <DeleteIcon />, token: "web.title.common.delete" },
  ];
  const handleMouseLeave = (event: any) => {
    setContentVisible(false);
  };
  const handleOptionSelect = (option: any) => {
    if (option.label === "Edit") {
      handleEdit(item);
    } else {
      handleDelete(item);
    }
  };

  const handleMoreOptions = (e: any) => {
    e.stopPropagation();
    setContentVisible(!isContentVisible);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target as Node)
      ) {
        setContentVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleError = (e: any, actionType: any) => {
    e.target.src = actionType === "user" ? defaultUserImage : defaultIconImage;
  };
  return (
    <div
      className="w-full h-auto cursor-pointer group"
      onMouseLeave={handleMouseLeave}
      onClick={() => handleNavigate(item)}
    >
      <div className="w-full h-auto rounded-[0.738rem] shadow-[rgba(0,0,0,0.1)_0px_0px_5px_0px,rgba(0,0,0,0.1)_0px_0px_1px_0px] bg-io-gray-fa dark:bg-io-black-1a group-hover:bg-io-white group-hover:dark:bg-io-black-29 group-hover:shadow-[0_3px_21.1px_0_rgba(0,0,0,0.11)]">
        <div className="w-full h-auto p-[1.8rem]">
          <div className="w-full h-auto flex justify-between items-start gap-4">
            <div className="flex-1 h-auto">
              <div className="w-full h-auto flex justify-start items-center gap-4 mb-8">
                <div className="w-[5rem]">
                  <div className="w-[4.5rem] h-[4.5rem] overflow-hidden rounded-full flex justify-center items-center">
                    <img
                      className="object-cover object-center"
                      src={
                        actionType === "user"
                          ? item.profileImageUrl
                            ? item.profileImageUrl
                            : defaultUserImage
                          : item.icon
                          ? item.icon
                          : defaultIconImage
                      }
                      alt="MagicImage"
                      onError={(e) => handleError(e, actionType)}
                    />
                  </div>
                </div>

                <div className="w-auto">
                  <p className="text-[1.8rem] font-inter line-clamp-1 text-ellipsis break-all font-semibold text-io-black dark:text-io-white leading-none mb-2">
                    {item.name}
                  </p>
                  <p className="text-[1.8rem] font-inter font-semibold text-io-black dark:text-io-white leading-none mb-2">
                    {item.firstName} {item.lastName}
                  </p>
                  {item.status && (
                    <div
                      className={`w-auto inline-block rounded-[0.2rem] px-2 py-1 bg-opacity-10
                                  ${
                                    item.status === "Published" &&
                                    "bg-io-green text-io-green"
                                  } 
                                  ${
                                    item.status === "Draft" &&
                                    "bg-io-orange text-io-orange"
                                  } 
                                   `}
                    >
                      <p className="text-[1.1rem] font-inter font-normal text-inherit">
                        {item.status}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-auto h-auto " ref={contentRef}>
              <div className="w-auto h-auto relative">
                {!isExternalUser && (
                  <IconBtn
                    size="w-[2rem]"
                    icon={<VerticalMoreBlueIcon />}
                    handleClick={(e: any) => handleMoreOptions(e)}
                  />
                )}
                {isContentVisible && (
                  <div className="w-auto h-auto absolute top-full right-[-1rem] pt-5">
                    <CardMoreOptions
                      options={options}
                      onSelectOption={handleOptionSelect}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full h-auto">
            {item.containerName && (
              <div className="w-full h-auto mb-4">
                <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                  {item.containerName} {item.lastName}
                </p>
              </div>
            )}
            {item.containerName && (
              <div className="w-full h-auto mb-4">
                <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                  {item.containerName} {item.lastName}
                </p>
              </div>
            )}
            {/* {item.firstName && (
              <div className="w-full h-auto mb-4">
                <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                  {item.firstName} {item.lastName}
                </p>
              </div>
            )} */}
            {item.email && (
              <div className="w-full h-auto mb-4">
                <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                  {item.email}
                </p>
              </div>
            )}
            {item.description && (
              <div className="w-full h-auto mb-4">
                <p
                  title={item.description}
                  className="text-[1.2rem] font-inter line-clamp-1 text-ellipsis font-normal text-io-gray-66 dark:text-io-black-d1"
                >
                  {item.description}
                </p>
              </div>
            )}
            {item.getStartedName && (
              <div className="w-full h-auto mb-4">
                <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                  {item.getStartedName}
                </p>
              </div>
            )}
            {item.createdDate && (
              <div className="w-full flex justify-start items-center mb-[0.2rem]">
                <div className="w-1/2 h-auto">
                  <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                    {t("web.common.label.createddate")}
                  </p>
                </div>
                <div className="w-1/2 h-auto">
                  <p className="text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white">
                    {item.createdAt}
                  </p>
                </div>
              </div>
            )}
            {item.createdAt && (
              <div className="w-full flex justify-start items-center mb-[0.2rem]">
                <div className="w-1/2 h-auto">
                  <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                    {t("web.common.label.createddate")}
                  </p>
                </div>
                <div className="w-1/2 h-auto">
                  <p className="text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white">
                    {item.createdAt}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default CardsViewCard;
