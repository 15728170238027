import { useEffect, useRef, useState } from "react";
import ExploreActions from "../explore-actions";
import ExploreCards from "../explore-cards";
import ExploreGrid from "../explore-grid";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteUser from "../user-management/delete-user";
import GridColumn from "../user-management/grid-columns";
import ErrorPopup from "../../UI/error-popup";
import PageLoader from "../../UI/page-loader";
import excelExport from "../../../services/excelExport";
import { magicDelete, magicListFetch } from "../../../services/authFunctions";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../my-context/MyContext";

function Explore({
  visionUpdated,
  handlePillarSelectionType,
  setVisionUpdated,
  handleClick,
}: any) {
  const { isExternalUser } = useMyContext();
  const { t } = useTranslation();
  const { magicType } = useParams();
  const [isGridView, setIsGridView] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [magicTypeCount, setMagicTypeCount] = useState<any>(null);
  const [type, setType] = useState<any>(magicType);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<number>(0);
  const [sortingColumnName, setSortingColumnName] = useState<string>("");
  const [startIndex, setStartIndex] = useState(0);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [magicData, setMagicDetailsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(35);
  const [initialLoading, setIntialLoading] = useState(true);
  const [finalFetch, setFinalFetch] = useState(false);
  const scrollGroundRef = useRef<any>(null);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [isRefreshed] = useState<any>(false);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "name",
    "description",
    "createdAt",
    "isCompleted",
  ]);
  const columnsData = [
    { label: "Magic Name", token: "web.magix.label.magicname", key: "name" },
    {
      label: "Magic Description",
      token: "web.magix.label.magicdescription",
      key: "description",
    },
    {
      label: "Created Date",
      token: "web.common.label.createddate",
      key: "createdAt",
    },
    {
      label: "Status",
      token: "web.common.label.status",
      key: "isCompleted",
    },
  ];
  //#region Common Function
  const handleEdit = (items: any) => {
    navigate(`/ai-magic-studio/vision/${type}/create/${items.uniqueId}`);
  };

  const handleDelete = async (items: any) => {
    setDeleteData(items);
    setShowDeletePopup(true);
  };
  const handleDataDelete = async (items: any) => {
    setShowDeletePopup(false);
    setPageLoader(true);
    const response = await magicDelete(items);
    if (response.success) {
      setMagicDetailsData([]);
      setStartIndex(0);
      setIsDeleted((prev) => !prev);
      setPageLoader(false);
    } else {
      setPageLoader(false);
    }
  };
  const handleSelectionType = (value: string) => {
    setMagicDetailsData([]);

    setStartIndex(0);
    setIsUpdated((pre: any) => !pre);
    setType(value);
    handlePillarSelectionType(value);
  };

  const handleSearch = (value: string) => {
    setSearchedQuery(value);
  };

  const handleCheckboxChange = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, key]);
    } else {
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item !== key)
      );
    }
  };
  //#endregion
  //#region Use Effect
  useEffect(() => {
    if (searchedQuery !== undefined) {
      setMagicDetailsData([]);
      setStartIndex(0);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);

  useEffect(() => {
    if (visionUpdated === true) {
      setMagicDetailsData([]);
      setStartIndex(0);
      setIsUpdated((pre: any) => !pre);
    }
  }, [visionUpdated]);

  useEffect(() => {
    listFetch(searchedInputVal, type);
    setVisionUpdated(undefined);
  }, [
    isFetchMore,
    sortingColumnName,
    sorting,
    searchedInputVal,
    isDeleted,
    isUpdated,
    isRefreshed,
  ]);

  //#endregion
  //#region list Fetch
  const listFetch = async (query: any, type: any) => {
    setIsLoading(true);
    setFinalFetch(false);
    setNoDataFound(false);
    const response = await magicListFetch(
      query,
      type,
      startIndex,
      limit,
      sorting,
      sortingColumnName
    );
    if (response.success) {
      const magicDetails = response.data?.listDetails;
      const magicTypeCounts = response.data?.count;
      setMagicTypeCount(magicTypeCounts);
      setMagicDetailsData((prevData: any) => [...prevData, ...magicDetails]);
      setTotalCount(magicDetails.length);
      setIsLoading(false);
      if (magicDetails.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
      if (startIndex < limit) {
        setIntialLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };
  //#endregion
  //#region Scroll

  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = scrollGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };
  //#endregion
  //#region Sorting
  const handleSort = (column: any, value: number, columnName: string) => {
    setMagicDetailsData([]);
    setStartIndex(0);
    setSorting(value);
    setSortingColumnName(columnName);
  };
  //#endregion
  //#region  Excel Export
  const [isExcelLoading, setExcelLoading] = useState(false);
  const handleExcel = async () => {
    setExcelLoading(true);
    const screenName = "container";
    const fileName = "Magic List";
    const sheetName = "Magic_List";
    try {
      await excelExport.excelExport(
        screenName,
        fileName,
        sheetName,
        columnsData
      );
      setExcelLoading(false);
    } catch (error) {
      console.error("Export failed:", error);
      setExcelLoading(false);
    }
  };
  //#endregion

  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <div className="w-full h-auto">
          <h2 className="text-[3rem] text-io-black font-inter font-semibold mb-6 dark:text-io-white">
            {t("web.magix.label.explore")}
          </h2>
          <ExploreActions
            isGridView={isGridView}
            setIsGridView={() => setIsGridView(!isGridView)}
            magicTypeCount={magicTypeCount}
            getSearchedQuery={(query: any) => setSearchedQuery(query)}
            handleSelectionType={handleSelectionType}
            fetchData={handleSearch}
            handleClick={handleClick}
            isLoading={isLoading}
          />
        </div>

        {isGridView ? (
          <ExploreCards
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            rowData={magicData}
            scrollGroundRef={scrollGroundRef}
            handleScroll={handleScroll}
            initialLoading={initialLoading}
            isLoading={isLoading}
            limit={limit}
            finalFetch={finalFetch}
            noDataFound={noDataFound}
          />
        ) : (
          <ExploreGrid
            selectedColumns={selectedColumns}
            rowSelection={false}
            moreOptions={!isExternalUser}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            rowData={magicData}
            scrollGroundRef={scrollGroundRef}
            handleScroll={handleScroll}
            initialLoading={initialLoading}
            isLoading={isLoading}
            limit={limit}
            finalFetch={finalFetch}
            sorting={sorting}
            sortingColumnName={sortingColumnName}
            handleSort={handleSort}
            handleCheckboxChange={handleCheckboxChange}
            noDataFound={noDataFound}
          />
        )}
      </div>
      {showDeletePopup && (
        <DeleteUser
          handleCancel={() => setShowDeletePopup(false)}
          rowData={deleteData}
          handleDelete={handleDataDelete}
          actionFlag="vision"
        />
      )}
      {showColumns && (
        <GridColumn
          handleClose={() => setShowColumns(false)}
          handleCheckboxChange={handleCheckboxChange}
          columnsData={columnsData}
          selectedColumns={selectedColumns}
        />
      )}
      {isServerError && (
        <ErrorPopup handleClose={() => setIsServerError(false)} />
      )}
      {pageLoader && <PageLoader />}
    </div>
  );
}
export default Explore;
