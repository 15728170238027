import { useNavigate, useParams } from "react-router-dom";
import { InputFieldWithoutForm, SelectField } from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import { ToggleButtonWithoutForm } from "../../../form/toggle-btn";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { masterAccess } from "../../../../services/InspectionOneServices";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

function CreateMasterDetailsForm({ userColumnUpdated }: any) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [mode, setMode] = useState("insert");
  const generateGuid = () => {
    return uuidv4();
  };
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    isPrimaryValue: false,
    isMandatory: false,
    isDependentMultiChoice: 0,
    multiChoiceKey: "",
    dependentColumnDetailsId: "",
    masterColumnDependentConfigs: [
      {
        columnDetailsId: "",
        dependentColumnDetailsId: "",
        dependentForColumnDetailsId: "",
      },
    ],
  });

  useEffect(() => {
    if (id !== "new") {
      setMode("update");
    }
  }, [id]);
  const [errors, setErrors] = useState({ name: "", type: "" });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPrimarySelected, setIsPrimarySelected] = useState<boolean>(false);
  const [isMandatorySelected, setIsMandatorySelected] =
    useState<boolean>(false);

  const handleInputChange = (name: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleFormValidation = () => {
    const { name, type } = formData;
    const isNameEmpty = !name.trim();
    const isTypeEmpty = !type;
    setErrors({
      name: isNameEmpty ? t("web.error.title.namereq") : "",
      type: isTypeEmpty ? t("web.common.alert.plsselectdatatype") : "",
    });
    return !isNameEmpty && !isTypeEmpty;
  };

  const handleFormCancel = () => {
    navigate(-1);
  };

  const currentUrl: string = window.location.href;
  const parts = currentUrl.split("/");
  const masterId = parts[parts.length - 3];

  const handleColumnDetailsSubmit = async () => {
    try {
      const isValid = handleFormValidation();
      if (isValid) {
        setIsLoading(true);
        const idValue = mode === "insert" ? generateGuid() : id;
        const requestJson = {
          actionMode: 1,
          statusId: 1,
          masterColumnDetailsAction: [
            {
              id: idValue,
              accessControlUniqueId: masterId,
              name: formData.name,
              type: formData.type,
              isPrimaryKey: 0,
              isPrimaryValue: isPrimarySelected === true ? "1" : "0",
              isMandatory: isMandatorySelected === true ? "1" : "0",
              multiChoiceKey: formData.multiChoiceKey,
              IsDependentMultiChoice: formData.isDependentMultiChoice,
              masterColumnDependentConfigs:
                formData.masterColumnDependentConfigs,
            },
          ],
        };
        const response = await masterAccess.MasterColumnDetailsAction(
          requestJson
        );
        if (response.success) {
          setIsLoading(false);
          userColumnUpdated(true);
          navigate("/master-details/detail/" + masterId + "");
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };
  const handleToggleChange = (id: string, newState: any) => {
    if (id === "primaryValue") {
      setIsPrimarySelected(newState);
    } else {
      setIsMandatorySelected(newState);
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[93%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="w-full mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.master.label.name")}
                      type="text"
                      id="name"
                      isRequired={true}
                      value={formData.name}
                      error={t(errors.name)}
                      onChange={(e) => handleInputChange("name", e)}
                    />
                  )}
                </div>
                <div className="mb-[3rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <SelectField
                      labelName={t("web.master.label.datatype")}
                      options={[
                        { label: "Text", value: "1" },
                        { label: "DropDown", value: "2" },
                        { label: "Date & time", value: "3" },
                      ]}
                      id="dataType"
                      isRequired={true}
                      value={formData.type}
                      error={t(errors.type)}
                      onChange={(value) => handleInputChange("type", value)}
                    />
                  )}
                </div>
                <div className="w-full grid grid-cols-2 gap-4">
                  <div className="w-full mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <ToggleButtonWithoutForm
                        id="primaryValue"
                        size="large"
                        label={t("web.master.label.primaryvalue")}
                        isButtonChecked={formData.isPrimaryValue}
                        getIsChecked={(newState) =>
                          handleToggleChange("primaryValue", newState)
                        }
                      />
                    )}
                  </div>
                  <div className="w-full mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <ToggleButtonWithoutForm
                        id="mandatory"
                        size="large"
                        label={t("web.logic.label.mandatory")}
                        isButtonChecked={formData.isMandatory}
                        getIsChecked={(newState) =>
                          handleToggleChange("mandatory", newState)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
        handleSubmit={() => handleColumnDetailsSubmit()}
        isLoading={isLoading}
      />
    </div>
  );
}
export default CreateMasterDetailsForm;
