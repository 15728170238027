import ModalPageFooter from "../../../global-layout/modal-page-footer";
import IconBtn from "../../../form/icon-btn";
import { AddIcon, ImportIcon, LeftArrowIcon } from "../../../svg";
import IconTextBtn from "../../../form/icon-text-btn";
import ResponseValueEditor from "../../../form/response-value-editor";
import { Fragment } from "react/jsx-runtime";
import { JSX, useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { questionnaireOptions } from "../../../../services/InspectionOneServices";
import { useTranslation } from "react-i18next";
import { ToastError } from "../../../UI/toast";
import ToastifyMessage from "../../../../lib/toastify";
interface ResponseValueFormProps {
  goToSettings: () => void;
  handleImport: (file: File) => void;
  handleFormCancel: () => void;
  selectedResponseValue: (selectedItem: any) => void;
  responseValueOptions: any;
  selectedResponseItem: any;
  passUpdatedResponseval: (data: any) => void;
}
const responseValueFormSchema = yup.object().shape({
  responseValue: yup.string(),
});
function ResponseValueForm({
  goToSettings,
  handleImport,
  handleFormCancel,
  selectedResponseValue,
  responseValueOptions,
  selectedResponseItem,
  passUpdatedResponseval,
}: ResponseValueFormProps) {
  const { t } = useTranslation();
  const { ToastifyHandle } = ToastifyMessage();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({ resolver: yupResolver(responseValueFormSchema) });

  const handleError = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastError title={titleName} description={message} />,
      "error"
    );
  };

  const uploadFiles = useRef<HTMLInputElement>(null);
  const [updatedResponseValue, setUpdatedResponseValue] =
    useState<any>(responseValueOptions);

  useEffect(() => {
    if (selectedResponseItem?.groupId) {
      setValue("responseValue", selectedResponseItem.groupId);
    }
  }, [selectedResponseItem]);

  if (errors?.responseValue?.message) {
    handleError("web.toolbar.label.logic", errors?.responseValue?.message);
  }
  const handleFormSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      let requestJson = { questionOption: updatedResponseValue };
      const response: any =
        await questionnaireOptions.QuestionnaireOptionAction(requestJson);
      const responseData = response.data;
      if (response.success) {
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
    const selectedItem = updatedResponseValue.find(
      (item: any) => item.groupId === data.responseValue
    );
    if (selectedItem) {
      passUpdatedResponseval(updatedResponseValue);
      selectedResponseValue(selectedItem);
      goToSettings();
    }
  };

  const handleFileInputChange = (event: any) => {
    const file = event.target.files && event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const data = reader.result;
      if (data) {
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        handleImport(file);
        event.target.value = "";
      }
    };

    if (file) {
      reader.readAsBinaryString(file);
    }
  };

  const handleDeleteChoice = (data: any) => {};

  const handleUpdatedTags = (tags: any, groupData: any) => {
    const updatedOptions = updatedResponseValue.map((group: any) => {
      if (group.groupId === groupData.groupId) {
        const updatedGroup = {
          ...group,
          isChange: 1,
          options: tags,
        };
        return updatedGroup;
      }
      return group;
    });
    setUpdatedResponseValue(updatedOptions);
  };

  const handleAddResponse = () => {
    let maxId = Math.max(
      ...updatedResponseValue.map((item: any) => item.groupId)
    );
    if (maxId.toString() === "-Infinity") {
      maxId = 100;
    }
    const newDummyData = [
      { groupId: (maxId + 1).toString(), isChange: 0, options: [] },
    ];
    setUpdatedResponseValue([...updatedResponseValue, ...newDummyData]);
  };

  return (
    <form
      className="w-full h-full flex flex-col"
      onSubmit={handleSubmit((data) => handleFormSubmit(data))}
    >
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large pr-[4rem]">
        <div className="w-full h-auto flex justify-between items-center bg-io-white dark:bg-transparent mb-6 sticky top-0 z-[2]">
          <div className="w-auto h-auto flex justify-start items-center gap-4">
            <IconBtn
              size="w-[1.9rem]"
              icon={<LeftArrowIcon />}
              handleClick={() => goToSettings()}
            />
            <p className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white">
               {t("web.form.label.multiple.response")}
            </p>
          </div>
          <div className="w-auto flex justify-end items-center gap-[2rem]">
            <IconTextBtn
              type="button"
              label={t("web.logic.label.addresponse")}
              btnSize="large"
              icon={<AddIcon />}
              handleClick={handleAddResponse}
            />

            {/* <input type="file" id="importResponse" className="w-0 h-0 hidden" onChange={handleFileInputChange}
                        ref={uploadFiles} />
                        <label htmlFor="importResponse">
                            <div className="pointer-events-none">
                            <IconTextBtn label="Import" btnSize="large" icon={<ImportIcon />} />
                            </div>
                        </label> */}
          </div>
        </div>
        <div className="w-full h-auto pt-[1rem]">
          {updatedResponseValue.map((value: any, index: any) => (
            <Fragment key={index}>
              <ResponseValueEditor
                groupData={value}
                idName={value.groupId}
                chips={value.options}
                editorType="addTag"
                hasCloseBtn={false}
                hasRadioBtn={true}
                formHandle={register("responseValue")}
                error={errors?.responseValue?.message}
                schemaName={"responseValue"}
                handleDeleteChoice={handleDeleteChoice}
                getTags={handleUpdatedTags}
              />
            </Fragment>
          ))}
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
        isLoading={isLoading}
      />
    </form>
  );
}
export default ResponseValueForm;
