import { useTranslation } from "react-i18next";
import IconBtn from "../../form/icon-btn";
import { EditIcon } from "../../svg";
interface PreviewKeyWordsProps {
  isEditable: boolean;
}

function PreviewKeyWords({ isEditable }: PreviewKeyWordsProps) {
  const tags = ["Spell", "Check", "Spec", "Vec", "Spectra"];
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-between items-center mb-6">
        <h4 className="text-[2rem] font-inter font-normal text-io-gray-33 mb-2 dark:text-io-white">
          {t("web.label.common.keywords.spellcheck")}
        </h4>
        {isEditable && (
          <IconBtn
            size="w-[2rem]"
            icon={<EditIcon />}
            handleClick={() => console.log("asdasd")}
          />
        )}
      </div>
      <div className="w-full h-auto pb-8 border-b border-dashed border-io-gray-c8 dark:border-io-black-48 mb-[3rem]">
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66">
          Keywords
        </p>
        <div className="w-full h-auto p-6 bg-io-gray-f8 dark:bg-io-black-1a mt-[0.3rem]">
          <div className="w-full h-auto flex justify-start items-center flex-wrap gap-[0.8rem]">
            {tags.map((tag: string, index: number) => (
              <div
                key={index}
                className="w-auto px-[0.8rem] py-[0.5rem] bg-io-primary rounded-full"
              >
                <p className="text-[1.2rem] font-inter font-normal text-io-white">
                  {tag}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default PreviewKeyWords;
