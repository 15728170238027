const typeOfResponse = [
  {
    id: 1,
    groupId: 1,
    type: 1,
    subType: 0,
    displayText: "Tenant Name",
    createdAt: "1/4/2024 12:46:10.47",
    isDefault: 1,
  },
  {
    id: 2,
    groupId: 1,
    type: 2,
    subType: 0,
    displayText: "Site Name",
    createdAt: "1/4/2024 12:46:10.507",
    isDefault: 1,
  },
  {
    id: 3,
    groupId: 1,
    type: 3,
    subType: 0,
    displayText: "Person Name",
    createdAt: "1/4/2024 12:46:10.533",
    isDefault: 1,
  },
  {
    id: 4,
    groupId: 1,
    type: 4,
    subType: 0,
    displayText: "Location",
    createdAt: "1/4/2024 12:46:10.567",
    isDefault: 1,
  },
  {
    id: 5,
    groupId: 2,
    type: 5,
    subType: 0,
    displayText: "Scanner - All",
    createdAt: "1/4/2024 12:46:10.597",
    isDefault: 1,
  },
  {
    id: 6,
    groupId: 2,
    type: 5,
    subType: 1,
    displayText: "Scanner - BarCode",
    createdAt: "1/4/2024 12:46:10.63",
    isDefault: 1,
  },
  {
    id: 7,
    groupId: 2,
    type: 5,
    subType: 2,
    displayText: "Scanner - QRCode",
    createdAt: "1/4/2024 12:46:10.66",
    isDefault: 1,
  },
  {
    id: 8,
    groupId: 2,
    type: 5,
    subType: 3,
    displayText: "Scanner - OCR",
    createdAt: "1/4/2024 12:46:10.69",
    isDefault: 1,
  },
  {
    id: 9,
    groupId: 2,
    type: 6,
    subType: 0,
    displayText: "Multiple Choice - Radio",
    createdAt: "1/4/2024 12:46:10.717",
    isDefault: 1,
  },
  {
    id: 10,
    groupId: 2,
    type: 6,
    subType: 1,
    displayText: "Multiple Choice - Checkbox",
    createdAt: "1/4/2024 12:46:10.75",
    isDefault: 1,
  },
  {
    id: 11,
    groupId: 2,
    type: 6,
    subType: 2,
    displayText: "Multiple Choice - Dropdown",
    createdAt: "1/4/2024 12:46:10.78",
    isDefault: 1,
  },
  {
    id: 12,
    groupId: 2,
    type: 7,
    subType: 0,
    displayText: "Text Short",
    createdAt: "1/4/2024 12:46:10.81",
    isDefault: 1,
  },
  {
    id: 13,
    groupId: 2,
    type: 7,
    subType: 1,
    displayText: "Text Long",
    createdAt: "1/4/2024 12:46:10.84",
    isDefault: 1,
  },
  {
    id: 14,
    groupId: 2,
    type: 8,
    subType: 0,
    displayText: "Number",
    createdAt: "1/4/2024 12:46:10.87",
    isDefault: 0,
  },
  {
    id: 15,
    groupId: 2,
    type: 9,
    subType: 0,
    displayText: "Date",
    createdAt: "1/4/2024 12:46:10.897",
    isDefault: 1,
  },
  {
    id: 16,
    groupId: 2,
    type: 9,
    subType: 1,
    displayText: "Time",
    createdAt: "1/4/2024 12:46:10.93",
    isDefault: 1,
  },
  {
    id: 17,
    groupId: 2,
    type: 9,
    subType: 2,
    displayText: "Date Time",
    createdAt: "1/4/2024 12:46:10.96",
    isDefault: 1,
  },
  {
    id: 18,
    groupId: 2,
    type: 10,
    subType: 0,
    displayText: "Acknowledgment",
    createdAt: "1/4/2024 12:46:10.99",
    isDefault: 0,
  },
  {
    id: 19,
    groupId: 2,
    type: 11,
    subType: 0,
    displayText: "Information",
    createdAt: "1/4/2024 12:46:11.02",
    isDefault: 0,
  },
  {
    id: 20,
    groupId: 2,
    type: 12,
    subType: 0,
    displayText: "Signature",
    createdAt: "1/4/2024 12:46:11.047",
    isDefault: 1,
  },
  {
    id: 21,
    groupId: 2,
    type: 13,
    subType: 0,
    displayText: "Image",
    createdAt: "1/4/2024 12:46:11.077",
    isDefault: 0,
  },
  {
    id: 22,
    groupId: 2,
    type: 14,
    subType: 1,
    displayText: "Image with Inputs",
    createdAt: "1/4/2024 12:46:11.107",
    isDefault: 0,
  },
  {
    id: 23,
    groupId: 2,
    type: 15,
    subType: 0,
    displayText: "Video",
    createdAt: "1/4/2024 12:46:11.137",
    isDefault: 0,
  },
  {
    id: 24,
    groupId: 2,
    type: 16,
    subType: 0,
    displayText: "Magic Container",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 0,
  },
  {
    id: 25,
    groupId: 2,
    type: 17,
    subType: 0,
    displayText: "Audio",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 0,
  },
  {
    id: 26,
    groupId: 2,
    type: 18,
    subType: 0,
    displayText: "Toggle",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 1,
  },
  {
    id: 27,
    groupId: 2,
    type: 9,
    subType: 3,
    displayText: "Range Date",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 1,
  },
  {
    id: 6,
    groupId: 2,
    type: 6,
    subType: 3,
    displayText: "Multiple Choice - Dropdown Multi Select",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 1,
  },
  {
    id: 29,
    groupId: 2,
    type: 19,
    subType: 0,
    displayText: "Grids",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 1,
  },
];

export { typeOfResponse };
