import { useState } from "react";
import IconBtn from "../../../form/icon-btn";
import { SelectInput } from "../../../form/select-input";
import ModalPageWrapper from "../../../global-layout/modal-page-wrapper";
import { PageModalCloseIcon } from "../../../svg";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import { useTranslation } from "react-i18next";

interface ImportResponseProps {
  handleCancel: () => void;
  fileName: string;
}

function ImportResponse({ handleCancel, fileName }: ImportResponseProps) {
  const [selectAll, setSelectAll] = useState(false);

  const [choicesData, setChoicesData] = useState([
    { id: 1, label: "Good, Average, Poor", selected: false },
    { id: 2, label: "Pass, Fail, N/A", selected: false },
    { id: 3, label: "Complaint, Non Complaint, N/A", selected: false },
    { id: 4, label: "Good, Average, Poor", selected: false },
  ]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const newRows = choicesData.map((choice) => ({
      ...choice,
      selected: newSelectAll,
    }));
    setChoicesData(newRows);
  };

  const handleCheckboxChange = (id: number) => {
    const newRows = choicesData.map((choice) => {
      if (choice.id === id) {
        return { ...choice, selected: !choice.selected };
      }
      return choice;
    });
    setChoicesData(newRows);
    const allSelected = newRows.every((choice) => choice.selected);
    setSelectAll(allSelected);
  };

  const screenNameOptions = [
    { value: "multipleChoice", label: "Multiple Choice" },
    { value: "radioButton", label: "Radio Button" },
    { value: "dropDown", label: "DropDown" },
  ];

  const getSelectedItems = () => {
    return choicesData.filter((choice) => choice.selected);
  };

  const handleSubmit = () => {
    const selectedItems = getSelectedItems();
  };
  const { t } = useTranslation();
  return (
    <ModalPageWrapper>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[50%] h-auto bg-io-white dark:bg-io-black-1a rounded-[2.2rem] max-md:w-[68%]">
          <div className="w-full h-auto flex justify-between items-center px-[4.5rem] pt-[4.2rem] pb-[1.5rem] border-b border-io-gray-c8">
            <div className="w-auto h-auto">
              <h4 className="text-[2.8rem] font-inter font-medium text-io-black dark:text-io-white leading-none">
                {t("web.common.title.filename")} “<span>{fileName}</span>”
              </h4>
            </div>
            <div className="w-auto h-auto">
              <IconBtn
                size="w-[3rem]"
                icon={<PageModalCloseIcon />}
                handleClick={() => handleCancel()}
              />
            </div>
          </div>
          <div className="w-full h-auto px-[4.5rem] pt-[3rem]">
            <div className="w-1/2 h-auto mb-[3rem]">
              {/* <SelectInput labelName="screen Name" id="screenName" isRequired={true} options={screenNameOptions} /> */}
            </div>
            <div className="w-full h-auto">
              <div className="w-full h-auto">
                <div className="w-full h-auto flex justify-start items-center gap-8 bg-io-gray-e9 dark:bg-io-black-29 px-8 py-6 rounded-t-[1rem]">
                  <div className="w-auto">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </div>
                  <p className="text-[1.8rem] font-inter font-semibold text-io-black dark:text-io-white">
                    {t("web.logic.response.choices")}
                  </p>
                </div>
              </div>
              <div className="w-full h-[30vh] overflow-y-auto">
                {choicesData.map((choice) => (
                  <div
                    key={choice.id}
                    className="w-full h-auto flex justify-start items-center gap-8 px-8 py-6"
                  >
                    <div className="w-auto">
                      <input
                        type="checkbox"
                        checked={choice.selected}
                        onChange={() => handleCheckboxChange(choice.id)}
                      />
                    </div>
                    <p className="text-[1.7rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 leading-none">
                      {choice.label}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full h-auto">
              <ModalPageFooter
                footerType="form"
                handleCancel={() => console.log("assad")}
                handleSubmit={() => console.log("assad")}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default ImportResponse;
