import Header from "../components/global-layout/header";
import SideNav from "../components/global-layout/sidenav";
import MainArea from "../components/global-layout/main-area";
import Confetti from "../lib/confetti";
import { useEffect, useState } from "react";
import Notification from "../components/section/notification";
import HelpAndSupport from "../components/section/help-and-support";
import Settings from "../components/section/settings";
import ViewAccount from "../components/section/view-account";
import Profile from "../components/section/profile";
import { ToastContainer } from "react-toastify";
import ConfirmSiteSwitch from "../components/section/confirm-site-switch";
import {
  sidebarDetails,
  siteSwitch,
  userEdit,
} from "../services/authFunctions";
import Authentication from "../auth/Authentication";
import { useNavigate } from "react-router-dom";
interface LayoutProps {
  confettiVal: boolean;
  themeToggled: () => void;
  children: any;
}

function Layout({ confettiVal, themeToggled, children }: LayoutProps) {
  const [sideNavState, setSideNavState] = useState<boolean>(true);
  const [headerModal, setHeaderModal] = useState<string>("");
  const [isConfetti, setIsConfetti] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [siteSwitchModal, setSiteSwitchModal] = useState(false);
  const [confirmSiteSwitch, setConfirmSiteSwitch] = useState(false);
  const [confirmSiteData, setConfirmSiteData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const authService = Authentication();
  var userDetails = authService.decryptData("UserDetails", 2);
  const handleSiteSwitch = async (siteId: any) => {
    sessionStorage.setItem("siteSwitchId", siteId);
    userDetails.lastAccess = siteId === "all" ? -1 : 1;
    authService.encryptData(userDetails, "UserDetails", 2);
    setIsLoading(true);

    return await siteSwitch(siteId);
  };
  const navigate = useNavigate();
  useEffect(() => {
    const handleSiteSwitchAsync = async () => {
      if (confirmSiteSwitch) {
        await handleSiteSwitch(confirmSiteData.id);
        if (confirmSiteData.id !== "all") {
          sessionStorage.setItem("siteId", confirmSiteData.id);
          sessionStorage.setItem("tenantId", confirmSiteData.tenantId);
          var result = await sidebarDetails(
            userDetails.roleId,
            confirmSiteData.id
          );
          const response = await userEdit(sessionStorage.getItem("userId"));
          if (response.success) {
            response.data.imageUrl = response.data.profileImageUrl;
            authService.encryptData(response.data, "UserDetails", 2);
          }
          authService.encryptData(result?.sideMenu, "sideBarDetails", 2);
          navigate(result?.activeMenu.routerLink);
        }
        window.location.reload();
      } else {
      }
    };
    handleSiteSwitchAsync();
  }, [confirmSiteSwitch]);

  useEffect(() => {
    setIsConfetti(confettiVal);
  }, [confettiVal]);

  const handleChangePassword = () => {
    setHeaderModal("settings");
    setShowChangePassword(true);
  };

  return (
    <>
      {children}
      <div className="w-full h-screen overflow-hidden">
        <div className="w-full h-full flex flex-col">
          <Header
            activeModal={headerModal}
            handleClick={(data) => setHeaderModal(data)}
            themeToggled={themeToggled}
          />
          <div className="w-full flex-1 relative">
            <div className="w-full h-full">
              <div
                className={`w-full h-full flex justify-between items-center layout-wrapper ${
                  sideNavState ? "expanded" : "collapsed"
                } `}
              >
                {isConfetti && <Confetti numberOfPieces={400} />}
                <SideNav sideNavState={(data: any) => setSideNavState(data)} />
                <MainArea />
              </div>
            </div>
            {headerModal === "notification" && (
              <Notification
                handleClose={() => setHeaderModal("")}
                handleChangePassword={handleChangePassword}
              />
            )}
            {headerModal === "helpandsupport" && (
              <HelpAndSupport handleClose={() => setHeaderModal("")} />
            )}
            {headerModal === "viewaccount" && (
              <ViewAccount handleClose={() => setHeaderModal("")} />
            )}
            {headerModal === "settings" && (
              <Settings
                handleClose={() => setHeaderModal("")}
                showChangePassword={showChangePassword}
              />
            )}
            {headerModal === "profile" && (
              <Profile
                handleViewAccount={() => setHeaderModal("viewaccount")}
                handleClose={() => setHeaderModal("")}
                setSiteSwitchModal={(data: any) => setSiteSwitchModal(data)}
                setConfirmSiteData={setConfirmSiteData}
              />
            )}
            {siteSwitchModal && (
              <ConfirmSiteSwitch
                handleClose={() => {
                  setSiteSwitchModal(false);
                  setHeaderModal("");
                }}
                handleSiteSwitchConfirm={() => setConfirmSiteSwitch(true)}
                isLoading={isLoading}
              />
            )}

            {/* <Alert /> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default Layout;
