import { ReactNode, useEffect, useRef, useState } from "react";
import { AddIcon, HidePassword, ShowPassword } from "../../svg";

interface InputFieldProps {
  labelName?: string;
  type?: string;
  id: string;
  isRequired?: boolean;
  value?: string;
  onChange?: (event: any) => void;
  hasAddBtn?: boolean;
  handleAdd?: (e: any) => void;
  focus?: boolean;
  error?: any;
  textColor?: string;
  formHandle?: any;
  isReadOnly?: boolean;
  fieldIcon?: ReactNode;
  isLightThemeOnly?: boolean;
  key?: any;
  maxLength?: any;
  onCopy?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}
interface TextAreaFieldProps {
  labelName: string;
  type?: string;
  id: string;
  isRequired?: boolean;
  error?: string;
  formHandle?: any;
  isReadOnly?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  maxLength?: any;
}
interface Option {
  label: string;
  value: any;
}
interface SelectFieldProps {
  labelName: string;
  id: string;
  isRequired?: boolean;
  error?: string;
  options: Option[];
  value?: string;
  onChange?: (value: string) => void;
  isReadOnly?: boolean;
}

export const InputField = ({
  labelName,
  type,
  id,
  isRequired,
  value,
  formHandle,
  hasAddBtn,
  handleAdd,
  error,
  textColor = "text-io-black dark:text-io-white",
  isReadOnly = false,
  fieldIcon,
  isLightThemeOnly,
  key,
  maxLength = 30,
}: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative" key={key}>
      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        {...formHandle}
        name={id}
        readOnly={isReadOnly}
        className={`peer block w-full appearance-none border  ${
          isLightThemeOnly
            ? "border-io-gray-c1"
            : "border-io-gray-c1 dark:border-io-black-48"
        }  bg-transparent py-[1.2rem] px-[1.7rem] pl-[1.5rem] text-[1.4rem] ${
          textColor ? textColor : ""
        } focus:border-io-primary rounded-[3px] focus:outline-none focus:ring-0`}
        placeholder=" "
        // value={defaultValue}
        value={value}
        maxLength={maxLength}
      />
      {labelName && (
        <label
          className={`absolute top-5 ${
            isLightThemeOnly
              ? "bg-io-white text-io-gray-33"
              : "bg-io-white text-io-gray-33 dark:bg-io-black-15 dark:text-io-white"
          }  block px-2 origin-[0] left-[1rem] -translate-y-9 transform text-[1.2rem] duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-[1rem] peer-focus:-translate-y-9  pointer-events-none`}
        >
          {labelName} {isRequired && <span className="text-red-500">*</span>}{" "}
        </label>
      )}
      {type === "password" && (
        <button
          type="button"
          className="w-[2.1rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <ShowPassword /> : <HidePassword />}
        </button>
      )}
      {hasAddBtn && (
        <button
          type="button"
          className="w-[1.8rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]"
          onClick={handleAdd}
        >
          <AddIcon />
        </button>
      )}
      {fieldIcon && (
        <div className="w-[2rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]">
          {fieldIcon}
        </div>
      )}
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
          {error}
        </p>
      )}
    </div>
  );
};
export const TextAreaField = ({
  labelName,
  type,
  id,
  isRequired,
  error,
  formHandle,
  value,
  onChange,
  isReadOnly = false,
  maxLength = 255,
}: TextAreaFieldProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange !== undefined) {
      onChange(event.target.value);
    }
  };
  return (
    <div className="relative">
      <textarea
        rows={3}
        name={id}
        {...formHandle}
        readOnly={isReadOnly}
        className="peer block w-full appearance-none border border-io-gray-c1 dark:border-io-black-48 bg-transparent py-[1.2rem] px-[1.7rem] text-[1.4rem] text-io-black dark:text-io-white focus:border-io-primary rounded-[3px] focus:outline-none focus:ring-0"
        placeholder=" "
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
      ></textarea>
      <label className="absolute top-5 bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1rem] -translate-y-9 transform text-[1.2rem] text-io-gray-33 dark:text-io-black-d1 duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-[1rem] peer-focus:-translate-y-9 pointer-events-none">
        {labelName}
      </label>
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
          {error}
        </p>
      )}
    </div>
  );
};
export const SelectField = ({
  labelName,
  options,
  id,
  isRequired,
  error,
  value,
  onChange,
  isReadOnly = false,
}: SelectFieldProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (onChange) {
      onChange(selectedValue);
    }
  };
  return (
    <div className="relative">
      <select
        name={id}
        onChange={handleChange}
        value={value}
        disabled={isReadOnly}
        className="peer block w-full appearance-none border border-io-gray-c1 dark:border-io-black-48 bg-transparent py-[1.2rem] px-[1.7rem] text-[1.4rem] text-io-black dark:text-io-white focus:border-io-primary rounded-[3px] focus:outline-none focus:ring-0"
      >
        <option key={0} value="">
          Select Option
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
      <label className="absolute top-5 bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1.7rem] -translate-y-9 transform text-[1.2rem] text-io-gray-33 dark:text-io-black-72 duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-[1.7rem] peer-focus:-translate-y-9 peer-focus:text-io-primary peer-focus:dark:text-io-primary pointer-events-none">
        {labelName} {isRequired && <span className="text-red-500">*</span>}
      </label>
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
          {error}
        </p>
      )}
    </div>
  );
};

export const InputFieldWithoutForm = ({
  labelName,
  type,
  id,
  isRequired,
  value,
  onChange,
  hasAddBtn,
  handleAdd,
  focus,
  error,
  textColor = "text-io-black dark:text-io-white",
  isReadOnly = false,
  isLightThemeOnly,
  maxLength = 30,
  onCopy,
}: InputFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange !== undefined) {
      onChange(event.target.value);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="relative">
      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        name={id}
        ref={inputRef}
        readOnly={isReadOnly}
        className={`peer block w-full appearance-none border ${
          isLightThemeOnly
            ? "border-io-gray-c1 "
            : "border-io-gray-c1 dark:border-io-black-48"
        } bg-transparent py-[1.2rem] px-[1.7rem] pl-[1.5rem] text-[1.4rem] ${
          textColor ? textColor : ""
        } focus:border-io-primary rounded-[3px] focus:outline-none focus:ring-0`}
        placeholder=" "
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        onCopy={onCopy}
      />
      {labelName && (
        <label
          className={`absolute top-5 ${
            isLightThemeOnly
              ? "bg-io-white text-io-gray-33"
              : "bg-io-white text-io-gray-33 dark:bg-io-black-15 dark:text-io-white"
          }  block px-2 origin-[0] left-[1rem] -translate-y-9 transform text-[1.2rem]   duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-[1rem] peer-focus:-translate-y-9  pointer-events-none`}
        >
          {labelName} {isRequired && <span className="text-red-500">*</span>}{" "}
        </label>
      )}
      {type === "password" && (
        <button
          type="button"
          className="w-[2.1rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <ShowPassword /> : <HidePassword />}
        </button>
      )}
      {hasAddBtn && (
        <button
          type="button"
          className="w-[1.8rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]"
          onClick={handleAdd}
        >
          <AddIcon />
        </button>
      )}
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0 leading-none">
          {error}
        </p>
      )}
    </div>
  );
};

export const InputFieldMaxWithoutForm = ({
  labelName,
  type,
  id,
  isRequired,
  value,
  onChange,
  hasAddBtn,
  handleAdd,
  focus,
  error,
  textColor = "text-io-black dark:text-io-white",
  isReadOnly = false,
  isLightThemeOnly,
  maxLength = 1000,
  onCopy,
}: InputFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange !== undefined) {
      onChange(event.target.value);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="relative">
      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        name={id}
        ref={inputRef}
        readOnly={isReadOnly}
        className={`peer block w-full appearance-none border ${
          isLightThemeOnly
            ? "border-io-gray-c1 "
            : "border-io-gray-c1 dark:border-io-black-48"
        } bg-transparent py-[1.2rem] px-[1.7rem] pl-[1.5rem] text-[1.4rem] ${
          textColor ? textColor : ""
        } focus:border-io-primary rounded-[3px] focus:outline-none focus:ring-0`}
        placeholder=" "
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        onCopy={onCopy}
      />
      {labelName && (
        <label
          className={`absolute top-5 ${
            isLightThemeOnly
              ? "bg-io-white text-io-gray-33"
              : "bg-io-white text-io-gray-33 dark:bg-io-black-15 dark:text-io-white"
          }  block px-2 origin-[0] left-[1rem] -translate-y-9 transform text-[1.2rem]   duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-[1rem] peer-focus:-translate-y-9  pointer-events-none`}
        >
          {labelName} {isRequired && <span className="text-red-500">*</span>}{" "}
        </label>
      )}
      {type === "password" && (
        <button
          type="button"
          className="w-[2.1rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <ShowPassword /> : <HidePassword />}
        </button>
      )}
      {hasAddBtn && (
        <button
          type="button"
          className="w-[1.8rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]"
          onClick={handleAdd}
        >
          <AddIcon />
        </button>
      )}
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0 leading-none">
          {error}
        </p>
      )}
    </div>
  );
};
