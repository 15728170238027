import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScrollGrid from "../../../UI/infinite-scroll-grid";
import {
  ImageNameCellRenderer,
  MoreOptionsRenderer,
  NameCellRenderer,
  RowSelectionRenderer,
} from "../../../UI/grid-cells";
import { screenMapping } from "../../../../services/InspectionOneServices";

function SubProcessGrid({
  selectedColumns,
  rowSelection,
  moreOptions,
  handleNavigate,
  handleEdit,
  handleDelete,
  rowData,
  scrollGroundRef,
  handleScroll,
  initialLoading,
  isLoading,
  limit,
  finalFetch,
  sorting,
  sortingColumnName,
  handleSort,
  handleCheckboxChange,
  noDataFound,
}: any) {
  const [columnDefs, setColumnDefs] = useState<any>([
    {
      headerName: "Screen Mapping Name",
      token: "web.screenmapping.label.name",
      field: "name",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Screen Mapping Description",
      token: "web.screenmapping.label.desc",
      field: "description",
      width: "flex-1",
      align: "left",
    },
    // {
    //   headerName: "Startup Screen Name",
    //   token: "web.screenmapping.label.startupscreen",
    //   field: "getStartedName",
    //   width: "flex-1",
    //   align: "left",
    // },
    {
      headerName: "Created Date",
      token: "web.common.label.createddate",
      field: "createdAt",
      width: "flex-1",
      align: "left",
    },
  ]);

  const selectedMoreOption = (option: string, row: any) => {
    if (option === "edit") {
      handleEdit(row);
    } else {
      handleDelete(row);
    }
  };

  return (
    <div className="w-full h-full">
      <InfiniteScrollGrid
        columnDefs={columnDefs}
        rowData={rowData}
        initialLoading={initialLoading}
        searchedQuery=""
        handleScroll={handleScroll}
        chatGroundRef={scrollGroundRef}
        selectedColumns={selectedColumns}
        isLoading={isLoading}
        finalFetch={finalFetch}
        rowSelection={rowSelection}
        moreOptions={moreOptions}
        handleSort={handleSort}
        sorting={sorting}
        sortingColumnName={sortingColumnName}
        noDataFound={noDataFound}
      >
        {rowData?.map((rowData: any, i: number) => (
          <Fragment key={i}>
            <tr className="w-full h-auto flex justify-between p-[0.65rem] border-b border-[#EDEDED] hover:bg-[#eff8ff] dark:hover:bg-[#112A46]">
              {/* <RowSelectionRenderer
                rowData={rowData}
                rowSelection={rowSelection}
                handleCheckboxChange={handleCheckboxChange}
              /> */}
              <ImageNameCellRenderer
                cellKey="name"
                selectedColumns={selectedColumns}
                rowData={rowData}
                handleClick={handleNavigate}
              />
              <NameCellRenderer
                cellKey="description"
                selectedColumns={selectedColumns}
                rowData={rowData}
                handleClick={handleNavigate}
              />
              {/* <NameCellRenderer
                cellKey="getStartedName"
                selectedColumns={selectedColumns}
                rowData={rowData}
                handleClick={handleNavigate}
              /> */}
              <NameCellRenderer
                cellKey="createdAt"
                selectedColumns={selectedColumns}
                rowData={rowData}
                handleClick={handleNavigate}
              />
              <MoreOptionsRenderer
                rowData={rowData}
                moreOptions={moreOptions}
                selectedMoreOption={selectedMoreOption}
              />
            </tr>
          </Fragment>
        ))}
      </InfiniteScrollGrid>
    </div>
  );
}
export default SubProcessGrid;
