import IconBtn from "../../../form/icon-btn";
import {
  ExportIcon,
  ProcessCarIcon,
  ProcessMoreInfoIcon,
  ProcessVesselIcon,
} from "../../../svg";

import defaultIconImage from "../../../../assets/images/card-default-small.png";
import { useTranslation } from "react-i18next";

interface ProcessCardProps {
  item: Item;
  handleOpenMoreInfo: (item: Item) => void;
  handleExportClick: (item: Item) => void;
  handleNavigate: (item: Item) => void;
}
interface Item {
  icon: string;
  processName: string;
  processLogic: string;
  inspectedBy: string;
  verificationColour: any;
  createdDate: string;
}
function ProcessCard({
  item,
  handleOpenMoreInfo,
  handleExportClick,
  handleNavigate,
}: ProcessCardProps) {
  const { t } = useTranslation();
  var textColor = item?.verificationColour?.[0]?.textColor ?? "defaultColor";
  var fillColor = item?.verificationColour?.[0]?.fillColor ?? "defaultColor";
  var name = item?.verificationColour?.[0]?.name ?? "";
  const handleCellClicked = (params: any) => {
    handleNavigate(params);
  };
  return (
    <div className="w-full h-auto cursor-pointer group">
      <div className="w-full h-auto rounded-[0.738rem] shadow-[rgba(0,0,0,0.1)_0px_0px_5px_0px,rgba(0,0,0,0.1)_0px_0px_1px_0px] bg-io-gray-fa dark:bg-io-black-1a group-hover:bg-io-white group-hover:dark:bg-io-black-29 group-hover:shadow-[0_3px_21.1px_0_rgba(0,0,0,0.11)]">
        <div className="w-full h-auto p-[1.8rem]">
          <div className="w-full h-auto flex justify-between items-center gap-4">
            <div
              className="flex-1 h-auto"
              onClick={() => handleCellClicked(item)}
            >
              <div className="w-full h-auto flex justify-start items-center gap-4 mb-8">
                <div className="w-[4.5rem] h-[4.5rem] overflow-hidden rounded-full flex justify-center items-center">
                  <img
                    className="object-cover object-center"
                    src={item.icon ? item.icon : defaultIconImage}
                    alt="logic icon"
                  />
                </div>
                <div className="flex-1">
                  <p
                    className="text-[2rem] font-inter line-clamp-1 text-ellipsis break-all font-semibold text-io-black dark:text-io-white mb-1"
                    title={item.processName}
                  >
                    {item.processName}
                  </p>
                  <div
                    className={`w-auto inline-block rounded-[0.2rem] px-2 py-1 bg-opacity-10`}
                    style={{
                      color: textColor,
                      backgroundColor: fillColor,
                      borderColor: textColor,
                    }}
                  >
                    <p className="text-[1.1rem] font-inter font-normal text-inherit">
                      {name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <IconBtn
              size="w-[2rem]"
              icon={<ProcessMoreInfoIcon />}
              handleClick={() => handleOpenMoreInfo(item)}
            />
            {/* <IconBtn
              size="w-[2rem]"
              icon={<ExportIcon />}
              handleClick={() => handleExportClick(item)}
            /> */}
          </div>
          <div className="w-full h-auto">
            <div className="w-full flex justify-start items-center mb-[0.2rem]">
              <div className="w-1/2 h-auto">
                <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                  {t("web.logic.label.name")}
                </p>
              </div>
              <div className="w-1/2 h-auto">
                <p className="text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white">
                  {item.processLogic}
                </p>
              </div>
            </div>
            <div className="w-full flex justify-start items-center mb-[0.2rem]">
              <div className="w-1/2 h-auto">
                <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                  {t("web.common.label.inspectedby")}
                </p>
              </div>
              <div className="w-1/2 h-auto">
                <p className="text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white">
                  {item.inspectedBy}
                </p>
              </div>
            </div>
            <div className="w-full flex justify-start items-center mb-[0.2rem]">
              <div className="w-1/2 h-auto">
                <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                  {t("web.common.label.createddate")}
                </p>
              </div>
              <div className="w-1/2 h-auto">
                <p className="text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white">
                  {item.createdDate}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProcessCard;
