import Tippy from "@tippyjs/react";
import VoiceSearchInput from "../../../form/voice-search-input";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import {
  ResponseAcknowledgementIcon,
  ResponseAudioIcon,
  ResponseDateTimeIcon,
  ResponseImageIcon,
  ResponseImageWithInputIcon,
  ResponseInformationIcon,
  ResponseLocationIcon,
  ResponseMagicContainerIcon,
  ResponseMultipleChoiceIcon,
  ResponseNumberIcon,
  ResponsePersonNameIcon,
  ResponseScannerIcon,
  ResponseSignatureIcon,
  ResponseSiteNameIcon,
  ResponseTenantNameIcon,
  ResponseTextIcon,
  ResponseToggleIcon,
  ResponseVideoIcon,
  ResponseGridsIcon
} from "../../../svg";

interface SelectTypeOfResponseProps {
  tippyRef: any;
  visible: boolean;
  // show: () => void;
  hide: () => void;
  children: any;
  handleChangeTypeOfResponse: () => void;
  currentProcess: string;
}

interface ReponseTypesProps {
  items: any;
  typeOfResponseIcons: any;
  currentProcess: any;
  handleChangeTypeOfResponse: (data: any) => void;
  hide: () => void;
}
const typeOfResponse = [
  {
    id: 1,
    groupId: 1,
    type: 1,
    subType: 0,
    displayText: "Tenant Name",
    token: "web.logic.label.tenantname",
    createdAt: "1/4/2024 12:46:10.47",
    isDefault: 1,
  },
  {
    id: 2,
    groupId: 1,
    type: 2,
    subType: 0,
    displayText: "Site Name",
    token: "web.logic.label.sitename",
    createdAt: "1/4/2024 12:46:10.507",
    isDefault: 1,
  },
  {
    id: 3,
    groupId: 1,
    type: 3,
    subType: 0,
    displayText: "Person Name",
    token: "web.logic.label.personname",
    createdAt: "1/4/2024 12:46:10.533",
    isDefault: 1,
  },
  {
    id: 4,
    groupId: 1,
    type: 4,
    subType: 0,
    displayText: "Location",
    token: "web.logic.label.location",
    createdAt: "1/4/2024 12:46:10.567",
    isDefault: 1,
  },
  {
    id: 5,
    groupId: 2,
    type: 5,
    subType: 0,
    displayText: "Scanner - All",
    token: "web.logic.label.scannerall",
    createdAt: "1/4/2024 12:46:10.597",
    isDefault: 1,
  },
  {
    id: 6,
    groupId: 2,
    type: 5,
    subType: 1,
    displayText: "Scanner - BarCode",
    token: "web.logic.label.scannerbarcode",
    createdAt: "1/4/2024 12:46:10.63",
    isDefault: 1,
  },
  {
    id: 7,
    groupId: 2,
    type: 5,
    subType: 2,
    displayText: "Scanner - QRCode",
    token: "web.logic.label.scannerqrcode",
    createdAt: "1/4/2024 12:46:10.66",
    isDefault: 1,
  },
  {
    id: 8,
    groupId: 2,
    type: 5,
    subType: 3,
    displayText: "Scanner - OCR",
    token: "web.logic.label.scannerocr",
    createdAt: "1/4/2024 12:46:10.69",
    isDefault: 1,
  },
  {
    id: 9,
    groupId: 2,
    type: 6,
    subType: 0,
    displayText: "Multiple Choice - Radio",
    token: "web.logic.label.multiplechoiceradio",
    createdAt: "1/4/2024 12:46:10.717",
    isDefault: 1,
  },
  {
    id: 10,
    groupId: 2,
    type: 6,
    subType: 1,
    displayText: "Multiple Choice - Checkbox",
    token: "web.logic.label.multiplechoicecheckbox",
    createdAt: "1/4/2024 12:46:10.75",
    isDefault: 1,
  },
  {
    id: 11,
    groupId: 2,
    type: 6,
    subType: 2,
    displayText: "Multiple Choice - Dropdown",
    token: "web.logic.label.multiplechoicedropdown",
    createdAt: "1/4/2024 12:46:10.78",
    isDefault: 1,
  },
  {
    id: 12,
    groupId: 2,
    type: 7,
    subType: 0,
    displayText: "Text Short",
    token: "web.logic.label.textshort",
    createdAt: "1/4/2024 12:46:10.81",
    isDefault: 1,
  },
  {
    id: 13,
    groupId: 2,
    type: 7,
    subType: 1,
    displayText: "Text Long",
    token: "web.logic.label.textlong",
    createdAt: "1/4/2024 12:46:10.84",
    isDefault: 1,
  },
  {
    id: 14,
    groupId: 2,
    type: 8,
    subType: 0,
    displayText: "Number",
    token: "web.logic.label.number",
    createdAt: "1/4/2024 12:46:10.87",
    isDefault: 0,
  },
  {
    id: 15,
    groupId: 2,
    type: 9,
    subType: 0,
    displayText: "Date",
    token: "web.logic.label.date",
    createdAt: "1/4/2024 12:46:10.897",
    isDefault: 0,
  },
  {
    id: 16,
    groupId: 2,
    type: 9,
    subType: 1,
    displayText: "Time",
    token: "web.logic.label.time",
    createdAt: "1/4/2024 12:46:10.93",
    isDefault: 0,
  },
  {
    id: 17,
    groupId: 2,
    type: 9,
    subType: 2,
    displayText: "Date Time",
    token: "web.logic.label.datetime",
    createdAt: "1/4/2024 12:46:10.96",
    isDefault: 0,
  },
  {
    id: 18,
    groupId: 2,
    type: 10,
    subType: 0,
    displayText: "Acknowledgment",
    token: "web.logic.label.acknowledgment",
    createdAt: "1/4/2024 12:46:10.99",
    isDefault: 0,
  },
  {
    id: 19,
    groupId: 2,
    type: 11,
    subType: 0,
    displayText: "Information",
    token: "web.logic.label.information",
    createdAt: "1/4/2024 12:46:11.02",
    isDefault: 0,
  },
  {
    id: 20,
    groupId: 2,
    type: 12,
    subType: 0,
    displayText: "Signature",
    token: "web.logic.label.signature",
    createdAt: "1/4/2024 12:46:11.047",
    isDefault: 0,
  },
  {
    id: 21,
    groupId: 2,
    type: 13,
    subType: 0,
    displayText: "Image",
    token: "web.logic.label.image",
    createdAt: "1/4/2024 12:46:11.077",
    isDefault: 0,
  },
  {
    id: 22,
    groupId: 2,
    type: 14,
    subType: 0,
    displayText: "Image with Inputs",
    token: "web.logic.label.imagewithInputs",
    createdAt: "1/4/2024 12:46:11.107",
    isDefault: 0,
  },
  {
    id: 23,
    groupId: 2,
    type: 15,
    subType: 0,
    displayText: "Video",
    token: "web.logic.label.video",
    createdAt: "1/4/2024 12:46:11.137",
    isDefault: 0,
  },
  {
    id: 24,
    groupId: 2,
    type: 16,
    subType: 0,
    displayText: "Magix Container",
    token: "web.logic.label.magiccontainer",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 0,
  },
  {
    id: 25,
    groupId: 2,
    type: 17,
    subType: 0,
    displayText: "Audio",
    token: "web.logic.label.audio",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 0,
  },
  {
    id: 26,
    groupId: 2,
    type: 18,
    subType: 0,
    displayText: "Toggle",
    token: "web.logic.label.toggle",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 1,
  },
  {
    id: 27,
    groupId: 2,
    type: 9,
    subType: 3,
    displayText: "Range Date",
    token: "web.logic.label.rangeDate",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 0,
  },
  {
    id: 28,
    groupId: 2,
    type: 6,
    subType: 3,
    displayText: "Multiple Choice - Dropdown Multi Select",
    token: "web.logic.label.mcmp",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 1,
  },
  {
    id: 29,
    groupId: 2,
    type: 19,
    subType: 0,
    displayText: "Grids",
    token: "web.logic.label.grids",
    createdAt: "1/4/2024 12:46:11.163",
    isDefault: 1,
  },
];
function SelectTypeOfResponse({
  tippyRef,
  visible,
  hide,
  children,
  handleChangeTypeOfResponse,
  currentProcess,
}: SelectTypeOfResponseProps) {
  const [filteredResponses, setFilteredResponses] = useState(typeOfResponse);
  const typeOfResponseIcons = [
    { id: 1, icon: <ResponseTenantNameIcon /> },
    { id: 2, icon: <ResponseSiteNameIcon /> },
    { id: 3, icon: <ResponsePersonNameIcon /> },
    { id: 4, icon: <ResponseLocationIcon /> },
    { id: 5, icon: <ResponseScannerIcon /> },
    { id: 6, icon: <ResponseScannerIcon /> },
    { id: 7, icon: <ResponseScannerIcon /> },
    { id: 8, icon: <ResponseScannerIcon /> },
    { id: 9, icon: <ResponseMultipleChoiceIcon /> },
    { id: 10, icon: <ResponseMultipleChoiceIcon /> },
    { id: 11, icon: <ResponseMultipleChoiceIcon /> },
    { id: 12, icon: <ResponseTextIcon /> },
    { id: 13, icon: <ResponseTextIcon /> },
    { id: 14, icon: <ResponseNumberIcon /> },
    { id: 15, icon: <ResponseDateTimeIcon /> },
    { id: 16, icon: <ResponseDateTimeIcon /> },
    { id: 17, icon: <ResponseDateTimeIcon /> },
    { id: 18, icon: <ResponseAcknowledgementIcon /> },
    { id: 19, icon: <ResponseInformationIcon /> },
    { id: 20, icon: <ResponseSignatureIcon /> },
    { id: 21, icon: <ResponseImageIcon /> },
    { id: 22, icon: <ResponseImageWithInputIcon /> },
    { id: 23, icon: <ResponseVideoIcon /> },
    { id: 24, icon: <ResponseMagicContainerIcon /> },
    { id: 25, icon: <ResponseAudioIcon /> },
    { id: 26, icon: <ResponseToggleIcon /> },
    { id: 27, icon: <ResponseDateTimeIcon /> },
    { id: 28, icon: <ResponseMultipleChoiceIcon /> },
    { id: 29, icon: <ResponseMultipleChoiceIcon /> },
    { id: 29, icon: <ResponseMultipleChoiceIcon /> },
  ];

  const handleSearch = (query: any) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = typeOfResponse.filter(
      (item) =>
        item.displayText.toLowerCase().includes(lowercasedQuery) ||
        item.token.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredResponses(filtered);
  };

  const dropDownContent = (
    <div className="w-[40rem] h-[35vh] ">
      <div className="w-full h-full flex flex-col rounded-[1.5rem] bg-io-white dark:bg-[#1A1C28] border border-io-gray-df dark:border-io-black-48 pb-[1.4rem]">
        <div className="w-full h-auto px-[2rem] pt-[2rem] pb-[1.4rem]">
          <VoiceSearchInput inputSize="small" onSearch={handleSearch} />
        </div>
        <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-medium px-[2rem]">
          <ReponseTypes
            items={filteredResponses}
            typeOfResponseIcons={typeOfResponseIcons}
            currentProcess={currentProcess}
            handleChangeTypeOfResponse={handleChangeTypeOfResponse}
            hide={hide}
          />
        </div>
      </div>
    </div>
  );
  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="bottom"
      maxWidth={"500px"}
      delay={0}
    >
      <div className="w-full">{children}</div>
    </Tippy>
  );
}
const ReponseTypes: React.FC<ReponseTypesProps> = ({
  items,
  typeOfResponseIcons,
  currentProcess,
  handleChangeTypeOfResponse,
  hide,
}) => {
  const filteredItems = items.filter((response: any) => {
    if (currentProcess === "get-started") {
      return response.isDefault === 1;
    }
    return true;
  });

  const groupedResponses = filteredItems.reduce(
    (groups: any, response: any) => {
      const { groupId, subType } = response;
      if (!groups[groupId]) {
        groups[groupId] = [];
      }
      if (subType === 0) {
        groups[groupId].push(response);
      }
      return groups;
    },
    {}
  );
  const { t } = useTranslation();

  const hasItems = Object.keys(groupedResponses).length > 0;
  return (
    <ul className="w-full h-auto">
      {hasItems ? (
        Object.keys(groupedResponses).map((groupId: any, index: any) => (
          <Fragment key={groupId}>
            {groupedResponses[groupId].map((response: any, idx: any) => {
              const iconObj = typeOfResponseIcons.find(
                (icon: any) => icon.id === response.id
              );
              const iconComponent = iconObj ? iconObj.icon : null;
              return (
                <li
                  key={idx}
                  className="w-full h-auto group"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChangeTypeOfResponse(response);
                    hide();
                  }}
                >
                  <div className="w-full h-auto py-4 px-4 rounded-[0.3rem] bg-io-white group-hover:bg-[#F4F9FD] dark:bg-transparent dark:group-hover:bg-[#182233]">
                    <div className="w-full h-auto flex justify-between items-center ">
                      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem]">
                        <div className="w-[1.8rem] h-auto">{iconComponent}</div>
                        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
                          {t(response.token)}
                        </p>
                      </div>
                    </div>
                  </div>
                  {index !== Object.keys(groupedResponses).length - 1 &&
                    idx === groupedResponses[groupId].length - 1 && (
                      <div className="w-full h-auto border-b border-dashed border-io-gray-df dark:border-io-black-48 my-3"></div>
                    )}
                </li>
              );
            })}
          </Fragment>
        ))
      ) : (
        <li className="w-full h-auto py-4 px-4 rounded-[0.3rem] bg-io-white dark:bg-[#1A1C28]">
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none text-center">
            No data found
          </p>
        </li>
      )}
    </ul>
  );
};
export default SelectTypeOfResponse;
