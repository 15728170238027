import { useState, useEffect } from "react";
import { InputFieldWithoutForm } from "../../form/input-fields";
import { LoginBtn } from "../../form/text-btn";
import { useNavigate } from "react-router";
import Authentication from "../../../auth/Authentication";
import { useTranslation } from "react-i18next";
import { domainSubmit } from "../../../services/authFunctions";
import { domainValidation } from "../../../services/validation";
function DomainSteps({ setPageLoader }: any) {
  const navigate = useNavigate();
  const [subdomain, setSubdomain] = useState<string | null>("");
  const getSubdomain = () => {
    setPageLoader(true);
    const { hostname } = window.location;
    const hostParts = hostname.split(".");
    if (hostname.includes("localhost")) {
      setPageLoader(false);
      return "localhost";
    } else if (
      hostname.includes("inspectionone-host-staging.azurewebsites.net")
    ) {
      setPageLoader(false);
      return "inspectionone-host-staging.azurewebsites.net";
    }
    if (hostParts.length > 2) {
      setPageLoader(false);
      return hostParts[0];
    }
    return null;
  };

  useEffect(() => {
    setSubdomain(getSubdomain());
  }, []);

  const { t } = useTranslation();
  const authService = Authentication();
  const [isLoading, setIsLoading] = useState(false);
  //#region Domain
  const [domainValue, setDomain] = useState<string>("");
  const [errorDomainMessage, setDomainErrorMessage] = useState<string>("");
  const handleDomainChange = (value: string) => {
    setDomain(value);
  };
  const handleDomainValidation = () => {
    var errorMessage = domainValidation(domainValue);
    setDomainErrorMessage(errorMessage);
    return !errorMessage;
  };

  const handleDomainSubmit = async () => {
    if (handleDomainValidation()) {
      setIsLoading(true);
      setPageLoader(true);
      sessionStorage.clear();
      sessionStorage.setItem("domain", domainValue);
      const result = await domainSubmit(domainValue, true);
      if (result.success) {
        authService.login(1);
        sessionStorage.setItem("domain", domainValue);
        if (
          subdomain === "localhost" ||
          subdomain === "inspectionone-host-staging.azurewebsites.net"
        ) {
          authService.encryptData(result.data, "serviceDetails", 1);
          authService.encryptData(false, "isGridView", 2);
          navigate("/login");
        } else {
          window.location.href = `https://${domainValue}.inspectionone.ai/login`;
        }
        setIsLoading(false);
        setPageLoader(false);
      } else {
        setIsLoading(false);
        setPageLoader(false);
      }
    }
  };

  //#endregion

  const renderStepContent = () => {
    return (
      <div className="w-full h-auto mx-auto">
        <div className="w-full h-auto text-center">
          <div className="w-full h-auto mb-[5.4rem]">
            <h1 className="text-[4.2rem] font-inter font-bold text-io-black-1a mb-[0.7rem]">
              {t("web.domain.label.title")}
            </h1>
            <p className="text-[1.8rem] font-inter font-normal text-io-gray-66">
              {t("web.domain.label.subtitle")}
            </p>
          </div>
          <div className="w-[55.5%] h-auto mx-auto">
            <div className="w-full h-auto">
              <div className="mb-[5rem]">
                <InputFieldWithoutForm
                  labelName={t("web.domain.label.domain")}
                  type="text"
                  id="domain"
                  isRequired={true}
                  value={domainValue}
                  onChange={handleDomainChange}
                  error={t(errorDomainMessage)}
                  isLightThemeOnly={true}
                  textColor="text-io-black"
                />
              </div>
              <div className="w-full mb-[3rem]">
                <LoginBtn
                  label={t("web.login.button.login")}
                  btnType="button"
                  handleClick={handleDomainSubmit}
                  isLoading={isLoading}
                />
              </div>
              {/* <div className="formGroup">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      ref={captchaRef}
                    />
                  </div> */}
              {/* <LoginWithAzure /> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleCopy = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };
  return (
    <div className="w-full flex-1 overflow-y-auto">
      <div className="w-full h-full relative flex justify-center items-center ">
        {renderStepContent()}
      </div>
    </div>
  );
}
export default DomainSteps;
