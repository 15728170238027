import { useNavigate, useParams } from "react-router-dom";
import {
  InputFieldWithoutForm,
  TextAreaField,
} from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import ToastifyMessage from "../../../../lib/toastify";
import { useEffect, useState } from "react";
import { ToastError, ToastSuccess, ToastWarning } from "../../../UI/toast";
import { screenCollectionServices } from "../../../../services/InspectionOneServices";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import UploadImage from "../../../form/upload-image";

function NewSurveyForm({ surveyUpdated }: any) {
  const navigate = useNavigate();
  const { logicId, surveyId } = useParams();
  const { t } = useTranslation();
  const [mode, setMode] = useState("insert");
  const [isImageLoader, setImageLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    icon: "",
    imageUrl: "",
  });
  const [errors, setErrors] = useState({
    name: "",
  });
  const handleInputChange = (name: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const generateGuid = () => {
    return uuidv4();
  };
  const handleFormValidation = () => {
    const name = formData.name;
    const isNameEmpty = name.trim() === "";

    setErrors({
      name: isNameEmpty ? t("web.form.error.screennamereq") : "",
    });

    return !isNameEmpty;
  };
  const handleFormSubmit = async () => {
    if (handleFormValidation()) {
      try {
        setIsLoading(true);
        const requestJson = {
          ...formData,
          uniqueId: mode === "update" ? surveyId : generateGuid(),
          ProcessLogicUniqueId: logicId,
          name: formData.name,
          description: formData.description,
          icon: formData.imageUrl,
        };
        const response = await screenCollectionServices.ScreenCollectionAction(
          requestJson
        );
        if (response.success) {
          const successMessage =
            mode === "update"
              ? t("web.survey.title.srnupdate")
              : t("web.survey.title.scrncreate");

          handleSuccess(
            t("web.toaster.title.screencollection"),
            successMessage
          );
          setTimeout(() => {
            navigate(`/ai-logic-studio/process-logic/${logicId}`);
            surveyUpdated(true);
            setIsLoading(false);
          }, 1000);
        } else {
          setIsLoading(false);
          handleError(t("web.toolbar.label.process"), response.message);
          setTimeout(() => {}, 1000);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (surveyId !== "new") {
      setMode("update");
      handleSurveyEdit(surveyId);
    }
  }, [surveyId]);

  const handleSurveyEdit = async (uniqueIdId: any) => {
    try {
      setIsLoading(true);
      const requestJson = {
        uniqueId: uniqueIdId,
      };
      const response = await screenCollectionServices.ScreenCollectionDetail(
        requestJson
      );
      if (response.success) {
        setIsLoading(false);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...response.data[0],
          imageUrl: response.data[0].icon,
        }));
      } else {
        setIsLoading(false);
        handleError(t("web.toolbar.label.process"), response.message);
        setTimeout(() => {}, 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const handleFormCancel = () => {
    navigate(`/ai-logic-studio/process-logic/${logicId}`);
  };
  //#region Alert
  const { ToastifyHandle } = ToastifyMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const handleSuccess = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastSuccess title={titleName} description={message} />,
      "success"
    );
  };
  const handleError = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastError title={titleName} description={message} />,
      "error"
    );
  };
  const handleWarning = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastWarning title={titleName} description={message} />,
      "warning"
    );
  };
  //#endregion

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[93%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.screencollection.label.name")}
                      type="text"
                      id="processName"
                      isRequired={true}
                      value={formData.name}
                      error={t(errors.name)}
                      onChange={(e) => handleInputChange("name", e)}
                    />
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <TextAreaField
                      labelName={t("web.screencollection.label.desc")}
                      id="processDescription"
                      isRequired={true}
                      value={formData.description}
                      onChange={(e) => handleInputChange("description", e)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="w-[32%] h-auto">
              <div className="w-full h-auto">
                {isLoading ? (
                  <Skeleton width="100%" height={190} borderRadius={3} />
                ) : (
                  <UploadImage
                    formData={formData}
                    setFormData={setFormData}
                    isReadOnly={true}
                    isImageLoader={isImageLoader}
                    setImageLoader={setImageLoader}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={handleFormCancel}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
        isImageLoader={isImageLoader}
      />
    </div>
  );
}
export default NewSurveyForm;
